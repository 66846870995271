export const Flag = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64">
    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M7,0L7,64"
      style={{
        strokeDasharray: "64 66",
        strokeDashoffset: 0,
      }}></path>
    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M32.062,6L26,11L26,35L57,35L51,23L57,11L26,11"
      style={{
        strokeDasharray: "121, 123",
        strokeDashoffset: 0,
      }}></path>
    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M26,30L7,30L7,6L32,6L32,11"
      style={{
        strokeDasharray: "73 75",
        strokeDashoffset: 0,
      }}></path>
  </svg>
);

export const Setting = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64"
    //
  >
    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M32,1L26,1L26,10L20,12L14,6L6,14L12,20L10,26L1,26L1,38L10,38L12,44L6,50L14,58L20,52L26,54L26,63L32,63L38,63L38,54L44,52L50,58L58,50L52,44L54,38L63,38L63,26L54,26L52,20L58,14L50,6L44,12L38,10L38,1Z"
      style={{ strokeDasharray: "284, 286", strokeDashoffset: 0 }}></path>
    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M26,32A6,6 0,1,1 38,32A6,6 0,1,1 26,32"
      style={{ strokeDasharray: "38, 40", strokeDashoffset: 0 }}></path>
  </svg>
);

export const Circle = (
  <svg
    id="circle-svg"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64">
    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M1,32A31,31 0,1,1 63,32A31,31 0,1,1 1,32"
      style={{ strokeDasharray: "195, 197", strokeDashoffset: 0 }}></path>
    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M26,18L26,1"
      style={{ strokeDasharray: "17, 19", strokeDashoffset: 0 }}></path>

    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M38,18L38,1"
      style={{ strokeDasharray: "17, 19", strokeDashoffset: 0 }}></path>
    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M26,63L26,46"
      style={{ strokeDasharray: "17, 19", strokeDashoffset: 0 }}></path>

    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M38,63L38,46"
      style={{ strokeDasharray: "17, 19", strokeDashoffset: 0 }}></path>

    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M46,26L63,26"
      style={{ strokeDasharray: "17, 19", strokeDashoffset: 0 }}></path>
    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M46,38L63,38"
      style={{ strokeDasharray: "17, 19", strokeDashoffset: 0 }}></path>
    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M1,26L18,26"
      style={{ strokeDasharray: "17, 19", strokeDashoffset: 0 }}></path>

    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M1,38L18,38"
      style={{ strokeDasharray: "17, 19", strokeDashoffset: 0 }}></path>
  </svg>
);

export const Monitor = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    width="64px"
    height="64px"
    viewBox="0 0 64 64">
    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      width="62"
      height="41"
      d="M1 10 L63 10 L63 51 L1 51 Z"
      style={{ strokeDasharray: "206, 208", strokeDashoffset: 0 }}></path>

    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M22,63L42,63"
      style={{ strokeDasharray: "20, 22", strokeDashoffset: 0 }}></path>

    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M32,63L32,51"
      style={{ strokeDasharray: "12, 14", strokeDashoffset: 0 }}></path>
    <path
      fill="none"
      stroke="#000000"
      stroke-width="2"
      stroke-miterlimit="10"
      d="M1,43L64,43"
      style={{ strokeDasharray: "63, 65", strokeDashoffset: 0 }}></path>
  </svg>
);
