import React from "react";
import NavBar from "../Main_Pages/NavBar";

function Header_Main() {
  return (
    <>
      <NavBar />
    </>
  );
}

export default Header_Main;
