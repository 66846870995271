import React, { useEffect, useState } from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";

// MUI Component
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

// Custom Component
import Layout from "../../Layout/Layout";
import BreadCrumb from "../../Breadcrumb/BreadCrumb";
import Research_RAP from "../../assets/about-us/rap-analytical/pharmaceutical-research-at-rap-analytical.png";
import Training_RAP from "../../assets/about-us/rap-analytical/pharmaceutical-training-at-rap-analytical.png";
import Industry_Training_RAP from "../../assets/about-us/rap-analytical/pharmaceutical-industry-training-at-rap-analytical.png";
import Test_Training_RAP from "../../assets/about-us/rap-analytical/pharmaceutical-test-and-training-at-rap-analytical.png";
import {
  Flag,
  Setting,
  Circle,
  Monitor,
} from "../../assets/SvgIcons/HomeSVGIcons";

import Logo1 from "../../assets/homepage-logo/logo (1).png";
import Logo2 from "../../assets/homepage-logo/logo (2).png";
import Logo3 from "../../assets/homepage-logo/logo (3).png";
import Logo4 from "../../assets/homepage-logo/logo (4).png";
import Logo5 from "../../assets/homepage-logo/logo (5).png";
import Logo6 from "../../assets/homepage-logo/logo (6).png";
import Logo7 from "../../assets/homepage-logo/logo (7).png";
import Logo8 from "../../assets/homepage-logo/logo (8).png";
import Logo9 from "../../assets/homepage-logo/logo (9).png";
import Logo10 from "../../assets/homepage-logo/logo (10).png";
import Logo11 from "../../assets/homepage-logo/logo (11).png";
import Logo12 from "../../assets/homepage-logo/logo (12).png";
import Logo13 from "../../assets/homepage-logo/logo (13).png";
import Logo14 from "../../assets/homepage-logo/logo (14).png";
import Logo15 from "../../assets/homepage-logo/logo (15).png";
import Logo16 from "../../assets/homepage-logo/logo (16).png";
import Logo17 from "../../assets/homepage-logo/logo (17).png";
import Logo18 from "../../assets/homepage-logo/logo (18).png";

const logoSliderData = [
  Logo1,
  Logo2,
  Logo3,
  Logo4,
  Logo5,
  Logo6,
  Logo7,
  Logo8,
  Logo9,
  Logo10,
  Logo11,
  Logo12,
  Logo13,
  Logo14,
  Logo15,
  Logo16,
  Logo17,
  Logo18,
];

function Rap_Analytical() {
  const [yearCount, setYearCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [sessionCount, setSessionCount] = useState(0);
  const [placementCount, setPlacementCount] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      if (studentCount < 3092) {
        setStudentCount(studentCount + 4);
      }

      if (yearCount < 2012) {
        setYearCount(yearCount + 4);
      }

      if (sessionCount < 1926) {
        setSessionCount(sessionCount + 6);
      }

      if (placementCount < 90) {
        setPlacementCount(placementCount + 3);
      }
    }, 1);

    return () => {
      clearInterval(interval);
    };
  }, [studentCount, 5000]);

  return (
    <>
      <MetaTags>
        <title>About RAP Analytical | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/event"}>About RAP Analytical</BreadcrumbsItem>

      <Layout>
        <BreadCrumb name="About RAP Analytical" />

        <div
          className="container-fluid"
          style={{
            backgroundImage:
              "linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 100%)",
          }}>
          <div className="container">
            <section className="about-sec-1">
              <div className="about-img-1">
                <img src={Research_RAP} alt="" />
              </div>
              <div className="about-content-wrapper">
                <h5>
                  Why <strong>RAP Analytical</strong>
                </h5>
                <h2>
                  Construct a <mark>Stunning</mark> Career Perspective
                </h2>

                <Accordion className="about-accordion">
                  <AccordionSummary
                    className="accordion-header"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header">
                    <h6>
                      <strong> Practical Training </strong>
                    </h6>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    <p>
                      RAP Analytical provides and supports the act of teaching
                      and learning with the help of Practical Training Sessions.
                    </p>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="about-accordion">
                  <AccordionSummary
                    className="accordion-header"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <h6>
                      <strong> Theory Lectures</strong>
                    </h6>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    <p>
                      Study for a number of courses with help of ingeniously
                      designed Theory Lectures by RAP Analytical.
                    </p>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="about-accordion">
                  <AccordionSummary
                    className="accordion-header"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <h6>
                      <strong> Workshops</strong>
                    </h6>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    <p>
                      Studying with the help of Practical & Theory Sessions is
                      not just enough in today’s competitive education model.
                      RAP Analytical provides Extensive Workshops for a better
                      understanding.
                    </p>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="about-accordion">
                  <AccordionSummary
                    className="accordion-header"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <h6>
                      <strong> Seminars</strong>
                    </h6>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    <Typography>
                      We conduct various Seminars, Events, Recruitment drive and
                      various Social Experimental Activities for an in-depth
                      know-how of various Industries.
                    </Typography>
                  </AccordionDetails>
                </Accordion>

                <Accordion className="about-accordion">
                  <AccordionSummary
                    className="accordion-header"
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header">
                    <h6>
                      <strong> Academic Project Consulting </strong>
                    </h6>
                  </AccordionSummary>
                  <AccordionDetails className="accordion-details">
                    <p>
                      Additionally to various learning & curriculum activities,
                      RAP Analytical also provides Academic Project Consulting
                      by Expert & Experienced Guidance Coaches.
                    </p>
                  </AccordionDetails>
                </Accordion>
              </div>
            </section>

            <section className="about-sec-2">
              <div className="about-text-content">
                <h5>
                  More About <strong>RAP Analytical</strong>
                </h5>
                <h2>
                  Creating an Advanced <mark>Educational </mark> Ecosystem
                </h2>

                <p>
                  ‘RAP’ a new concept ever first time opened in Nashik,
                  established in 2012, which is playing a worthwhile role in the
                  field of Education and ultimately Pharmaceutical, Chemicals,
                  Bio-organics, Petroleum & many other Industries.
                </p>

                <p>
                  We observe number of students (B.Pharm., B.Sc., M. Pharm.,
                  M.Sc., and others) who are good at theory but lack in
                  practical skills, lack in ideas and operation of sophisticated
                  analytical instruments.
                </p>

                <p>
                  When students join industries they do not have experience of
                  operating analytical instruments so that they spend more time
                  in learning the instruments and industries can’t afford to
                  train them. With this purpose, we have started analytical
                  Instrument training center to train students when they are
                  actually doing their academic course and which will also help
                  them to cope their needs. Through this endeavor we want to
                  make students able to get job in esteemed fields as these
                  analytical instruments has been widely exploited in the
                  industries like Pharmaceutical, Food, Chemical, Bio-Organic,
                  Petroleum and many more.
                </p>

                <p>
                  RAP Analytical Research And Training Center is happy to see
                  you and would like to contribute in developing your skills
                  while you run towards your career success. We are glad to tell
                  you that RAP Analytical has trained more than 2000 students
                  yet and almost more than 75% of them got recruited in
                  different pharma and other MNC’s. We have also consulted more
                  than 1000 students for their Master’s and PhD research work.
                  We have reached up and collaborated with many educational
                  organizations in Maharashtra. We would like to share our
                  course details and would like to see you here in this course.
                  We are also proud to tell you that staff from different
                  companies have also completed their training at our site. We
                  look forward to train as many as possible students in same way
                  and to get best job opportunities for them with this skill.
                </p>
              </div>
              <div className="about-images">
                <img src={Training_RAP} width="100%" alt="" />
                <img src={Industry_Training_RAP} width="100%" alt="" />
              </div>
            </section>

            <div className="about-slider-content">
              <div className="slider">
                <div className="slide-track">
                  {logoSliderData.map((val, key) => {
                    return (
                      <div key={key} className="slide">
                        <img src={val} alt=""  width="200" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="curved-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              // height="100"
              style={{
                width: "100%",
                display: "block",
                position: "relative",
                left: "50%",
                transform: "translateX(-50%)",
                overflow: "hidden",
                height: "70px",
                // backgroundColor: "#f5f1ed",
              }}>
              <path
                class="elementor-shape-fill"
                style={{ fill: "#fff" }}
                d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"></path>
            </svg>
          </div>
        </div>

        <div >
          <div className="container">
            <section className="about-sec-3">
              <div className="about-text-record-wrapper">
                <div className="about-text-content">
                  <h5>
                    <strong>RAP Analytical</strong> in Numbers
                  </h5>

                  <h2>
                    <strong>Maximizing Your</strong>
                    <mark>Potentials</mark>
                    <strong> and </strong>
                    <mark>Opportunities</mark>
                  </h2>

                  <p>
                    Learn the secrets of Technical Career Success, and place the
                    right key in your career keyhole!
                  </p>

                  <h6>
                    Want More? <Link to="/courses"> View our Courses </Link>
                  </h6>
                </div>
                <div className="about-record-content">
                  <div className="single-record">
                    <h2>{sessionCount}+</h2>
                    <h6>Finished sessions</h6>
                  </div>

                  <div className="single-record">
                    <h2>{studentCount}+</h2>
                    <h6>Students Enrolled</h6>
                  </div>
                </div>

                <div className="about-record-content">
                  <div className="single-record">
                    <h2>{yearCount}</h2>
                    <h6>Established Year</h6>
                  </div>

                  <div className="single-record">
                    <h2>{placementCount}%+</h2>
                    <h6>Satisfaction Rate</h6>
                  </div>
                </div>
              </div>

              <h5>
                Experience at <strong>RAP Analytical</strong>
              </h5>
              <h1>
                <strong>Nurturing your </strong> <mark>Skills</mark>
              </h1>
            </section>

            <section className="about-sec-4">
              <div className="about-sec3-wrapper">
                <div className="cards-group">
                  <div className="single-card">
                    <div className="single-card-icon">{Flag}</div>
                    <div className="single-card-desc">
                      <h3>Technical Mentoring</h3>
                      <p>
                        'RAP' technical training for industries like
                        Pharmaceutical, Chemicals, Bio-Organics, Petroleum, and
                        more.
                      </p>
                    </div>
                  </div>

                  <div className="single-card">
                    <div className="single-card-icon-circle ">{Setting}</div>
                    <div className="single-card-desc">
                      <h3>Skill Development</h3>
                      <p>
                        We focus on developing skills for career. We encourage
                        potential in our students through skill mapping.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="cards-group">
                  <div className="single-card">
                    <div className="single-card-icon-circle ">{Circle}</div>
                    <div className="single-card-desc">
                      <h3>Ask for Guidance</h3>
                      <p>
                        Make a reservation with our expert consulting and
                        guidance team to learn what's best for your dream
                        career.
                      </p>
                    </div>
                  </div>

                  <div className="single-card">
                    <div className="single-card-icon">{Monitor}</div>
                    <div className="single-card-desc">
                      <h3>Remote Learning</h3>
                      <p>
                        Learn from anywhere in the world on desktop, tablet or
                        mobile phone with an Internet connection.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="about-img-2">
                  <img src={Test_Training_RAP} alt="" />
                </div>
              </div>
            </section>
          </div>
        </div>

        <div
          className="container-fluid"
          style={{
            background: "#F8F8F8",
          }}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1000 100"
            preserveAspectRatio="none"
            style={{
              // left: "50%",
              fill: "#fff",
              // transform: "rotateY(0deg)",
              // overflow: "hidden",
              // backgroundColor: "transparent",
            }}>
            <path
              class="elementor-shape-fill"
              d="M421.9,6.5c22.6-2.5,51.5,0.4,75.5,5.3c23.6,4.9,70.9,23.5,100.5,35.7c75.8,32.2,133.7,44.5,192.6,49.7
              c23.6,2.1,48.7,3.5,103.4-2.5c54.7-6,106.2-25.6,106.2-25.6V0H0v30.3c0,0,72,32.6,158.4,30.5c39.2-0.7,92.8-6.7,134-22.4
              c21.2-8.1,52.2-18.2,79.7-24.2C399.3,7.9,411.6,7.5,421.9,6.5z"></path>
          </svg>
          <section className="about-sec-5">
            <h4>
              <strong>Start today and get your </strong>
              <mark>Pharmaceutical Certification</mark>
            </h4>
            <h2>
              <strong>You can be your own guiding star with our help!</strong>
            </h2>

            <button>Get started now</button>
          </section>
        </div>
      </Layout>
    </>
  );
}

export default Rap_Analytical;
