import React from "react";
import { MetaTags } from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import Layout from "../Layout/Layout";
import BreadCrumb from "../Breadcrumb/BreadCrumb";
import EventImg from "../assets/events/workshops-research-methodology.png";
import { Link } from "react-router-dom";

function Event() {
  return (
    <>
      <MetaTags>
        <title>UpComing Events | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/event"}>UpComing Events</BreadcrumbsItem>

      <Layout>
        <BreadCrumb name="Archive: Events" />

        <div className="container">
          <div className="event-wrapper">
            <div className="event-card">
              <div className="event-info">
                <h5> JUL 30 2022 </h5>
                <h6>
                  {" "}
                  <strong>Featured</strong>{" "}
                  <mark> Jul 30 2022 @ 10:30 am - 1:30 pm IST </mark>{" "}
                </h6>

                <h4>
                  {" "}
                  <Link to="/event/event-info">
                    {" "}
                    Online Workshop on Research Methodology{" "}
                  </Link>
                </h4>

                <p>
                  Eligibility: For M.Pharm / M.Sc / Ph.D Canditates from all
                  Branches Workshop Coverage: Literature Survey Drugs / API
                  Selection Research Hypothesis Research Work Thesis Writeup
                  Referencing Appendix
                </p>
                <h5 className="mt-3">₹ 399</h5>
              </div>
              <div className="event-img">
                <Link to="/event/event-info">
                  <img src={EventImg} alt="" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Event;
