import React from "react";
import PlaystoreLogo from "../assets/footer-logo/download-play-store.png";
import BRIT_QUALIS from "../assets/footer-logo/BRIT-QUALIS-logo.png";
import ISO from "../assets/footer-logo/ISO-9001-Logo.png";
import NSDC from "../assets/footer-logo/nsdc-Logo.png";
import Skill_India from "../assets/footer-logo/skill-india-Logo.png";
import Trust_Seal from "../assets/footer-logo/Trust-seal.png";
import RAP_LOGO from "../assets/Logo/rap-analytical-logo.png";

// MUI Component
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Link } from "react-router-dom";
import { IconButton } from "@mui/material";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "@fortawesome/fontawesome-free/css/all.min.css";
import {
  faFacebookF,
  faTwitter,
  faInstagram,
  faLinkedinIn,
} from "@fortawesome/free-brands-svg-icons";

function Footer_Main() {
  return (
    <>
      {/* <div className="container-fluid mt-5 upper-footer">
        <div className="text">
          <h3 className="text-center">
            Download our <strong> mobile app </strong> on Play Store
          </h3>
          <h2 className="text-center">
            Unlock the <mark> Full Potential </mark>
          </h2>
        </div>
        <div className="play-store-logo">
          <img src={PlaystoreLogo} alt="" />
        </div>
      </div> */}

      <div className="container-fluid" style={{ backgroundColor: "#f8f8f8" }}>
        <div className="middle-footer">
          <div className="container d-flex flex-wrap justify-content-center p-4 company-logos">
            <img src={Skill_India} alt="" />
            <img src={NSDC} alt="" />
            <img src={ISO} alt="" />
            <img src={Trust_Seal} alt="" />
            <img src={BRIT_QUALIS} alt="" />
          </div>
        </div>

        <div className="lower-footer">
          <div className="address-content-wrapper">
            <div className="logo">
              <img src={RAP_LOGO} alt="" />
            </div>

            <div className="address-content">
              <h5 className="mt-3 mb-3">Address</h5>
              <div className="single-address-info">
                <div className="address-icon">
                  <LocationOnIcon sx={{ fontSize: "18px" }} />
                </div>
                <div className="address-info-dec">
                  <a
                    onClick={() =>
                      window.open(
                        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6067.464023151976!2d73.78363795640261!3d19.992867694410723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeb0394a742eb%3A0x473fc3176ced8f7e!2sRAP%20Analytical%20Research%20and%20Training%20Center!5e0!3m2!1sen!2sin!4v1681567848050!5m2!1sen!2sin"
                      )
                    }>
                    <p>
                      Second Floor, Circle Plaza, Sarda Cir, Shingada Talav,
                      Renuka Nagar, Nashik, Maharashtra 422001 - Click for
                      Google Maps
                    </p>
                  </a>
                </div>
              </div>

              <div className="single-address-info">
                <div className="address-icon">
                  <PhoneEnabledIcon sx={{ fontSize: "18px" }} />
                </div>
                <div className="address-info-dec">
                  <p>+91 7666 406 674</p>
                </div>
              </div>

              <div className="single-address-info">
                <div className="address-icon">
                  <PhoneEnabledIcon sx={{ fontSize: "18px" }} />
                </div>
                <div className="address-info-dec">
                  <p>+91 88888 297 88</p>
                </div>
              </div>

              <div className="single-address-info">
                <div className="address-icon">
                  <ForwardToInboxIcon sx={{ fontSize: "18px" }} />
                </div>
                <div className="address-info-dec">
                  <p>rapanalytical@gmail.com</p>
                </div>
              </div>
            </div>
          </div>

          <div className="site-list">
            <div className="site-navigation">
              <h5>Site Navigation</h5>
              <div className="d-flex">
                <ul>
                  <li className="cool-link">
                    <Link to="/demo">Home</Link>
                  </li>

                  <li className="dropdown cool-link">
                    <Link to="/testimonial">Our Team</Link>
                  </li>
                  <li className="cool-link">
                    <Link to="/testimonial">Testimonials</Link>
                  </li>
                  <li className="cool-link">
                    <Link to="/event">Events</Link>
                  </li>

                  <li className="cool-link">
                    <Link to="/gallary">T&C</Link>
                  </li>

                  <li className="cool-link">
                    <Link to="/gallary">Refund Policy</Link>
                  </li>
                </ul>

                <ul>
                  <li className="dropdown cool-link">
                    <Link to="/about/director_message">About us</Link>
                  </li>

                  <li className="cool-link">
                    <Link to="/about/director_message">Our Director</Link>
                  </li>
                  <li className="cool-link">
                    <Link to="/gallary">Gallery</Link>
                  </li>
                  <li className="cool-link">
                    <Link to="/contact">Contact</Link>
                  </li>

                  <li className="cool-link">
                    <Link to="/gallary">Privacy Poilcy</Link>
                  </li>

                  <li className="cool-link">
                    <Link to="/gallary">Sitemap</Link>
                  </li>
                </ul>
              </div>
            </div>

            <div className="all-courses">
              <h5>All Courses</h5>
              <ul>
                <li>
                  <Link to="/courses/foundation">Foundation Course</Link>
                </li>
                <li>
                  <Link to="/courses/regulatory_course">Regulatory Course</Link>
                </li>
                <li>
                  <Link to="/courses/marcket_research">Marcket Resaerch</Link>
                </li>
                <li>
                  <Link to="/courses/medical_coding">Medical Coding</Link>
                </li>
                <li>
                  <Link to="/courses/pharmcovigilance">PharmCovigilance</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className="icons-group">
          <strong className="text-center">Follow US : </strong>

          <div>
            <i onClick={() => window.open("https://twitter.com/RAPAnalytical")}>
              <FontAwesomeIcon icon={faTwitter} />
            </i>

            <i
              onClick={() =>
                window.open("https://www.facebook.com/RAPAnalytical")
              }>
              <FontAwesomeIcon icon={faFacebookF} />
            </i>
            <i
              onClick={() =>
                window.open("https://www.instagram.com/rapanalytical/")
              }>
              <FontAwesomeIcon icon={faInstagram} />
            </i>
            <i
              onClick={() =>
                window.open("https://www.linkedin.com/in/rapanalytical/")
              }>
              <FontAwesomeIcon icon={faLinkedinIn} />
            </i>
          </div>
        </div>

        <div className="copyright-content-wrapper">
          <div className="copyright-content">
            <p>
              © 2012- 2023 | RAP Analytical Research and Training Center | All
              Rights Reserved
            </p>
          </div>
          <div className="developer-contact">
            <p>
              Website Designed by
              {/* with            <mark>♥</mark>  */}
              <button onClick={() => window.open("http://flyctsofttech.com/")}>
                Flyctsofttech.com
              </button>
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer_Main;
