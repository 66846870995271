import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { MetaTags } from "react-meta-tags";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

import Layout from "../../Layout/Layout";
// MUI Component
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import LockIcon from "@mui/icons-material/Lock";

import ShareIcon from "@mui/icons-material/Share";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import CourseImg from "../../assets/courses/marcket-research/market-research-at-rap-analytical.png";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

function Marcket_Reasearch() {
  return (
    <>
      <MetaTags>
        <title>Marcket Research | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>

      <Layout>
        <div className="container">
          <div className="course-content-wrapper">
            <div className="course-desc-content">
              <div className="course-entry-image-wrapper">
                <div className="entry-course-info text-center">
                  <div className="course-instructor">
                    <AssignmentIcon />
                    <span className="meta-label"> Instructor </span>
                    <span className="meta-value">Team RAP</span>
                  </div>

                  <div className="course-instructor">
                    <WatchLaterIcon />
                    <span className="meta-label">Duration</span>
                    <span className="meta-value">7 days</span>
                  </div>

                  <div className="course-instructor">
                    <AccountCircleIcon />
                    <span className="meta-label">Enrolled</span>
                    <span className="meta-value">0 students</span>
                  </div>

                  <div className="course-instructor">
                    <GTranslateIcon />
                    <span className="meta-label">Language</span>
                    <span className="meta-value">English</span>
                  </div>

                  <div className="icons-tooltip text-center">
                    Share this course <ShareIcon sx={{ fontSize: "16px" }} />
                    <span className="icons-tooltiptext">
                      {" "}
                      <div className="icons-group">
                        <button
                          onClick={() =>
                            window.open("https://twitter.com/RAPAnalytical")
                          }>
                          <TwitterIcon />
                        </button>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.facebook.com/RAPAnalytical"
                            )
                          }>
                          <FacebookIcon />
                        </IconButton>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.instagram.com/rapanalytical/"
                            )
                          }>
                          <InstagramIcon />
                        </IconButton>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.linkedin.com/in/rapanalytical/"
                            )
                          }>
                          <LinkedInIcon />
                        </IconButton>
                      </div>
                    </span>
                  </div>

                  <h4>
                    <strong>
                      <Link
                        to={"/foundation-course-registration"}
                        state={{
                          data: {
                            title: "Market Research",
                            fee: "5,000",
                          },
                        }}>
                        Buy Now
                      </Link>
                    </strong>
                  </h4>
                </div>
                <div className="course-img">
                  <img src={CourseImg} alt="" />
                </div>
              </div>

              <Tab.Container defaultActiveKey="overview">
                <Nav variant="pills" className="course-info-tab-list">
                  <Nav.Item>
                    <Nav.Link eventKey="overview">
                      <h4>OverView</h4>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="overview">
                    <div className="course-desc">
                      <h3>
                        {" "}
                        <strong>
                          {" "}
                          The Basics of Pharmaceutical Market Research
                        </strong>
                      </h3>

                      <p>
                        The pharmaceutical industry is a multi-billion-dollar
                        business tasked with researching, developing,
                        manufacturing and distributing drugs for human and
                        animal use. The stakes are high; lives and money are on
                        the line each time a new medicine is conceived. And
                        because the process from drug conception to its delivery
                        is a long one, it's important that companies do the
                        necessary research to ensure that they are meeting a
                        worthy demand and have the requisite tools and
                        information that they need to succeed. Otherwise, they
                        risk losing a lot of money and a lot of time on a
                        product that no one wants or needs. Combine that with
                        the changing landscape of the pharma industry - this
                        point in time of drug history when key drug patents are
                        expiring (typically after a 20-year period) and more and
                        more generics are hitting the market - and it's easy to
                        understand that pharmaceutical companies must find new
                        drugs, as well as boost declining sales of their
                        established ones if they want to survive. Take a look at
                        the top five basic ways that pharmaceutical market
                        research can improve the drug industry from start to
                        finish:
                      </p>

                      <p>
                        {" "}
                        <strong>Identify Consumer Need</strong>{" "}
                      </p>

                      <p>
                        Big pharma doesn't just serve patients, it also serves a
                        host of other types of consumers, from the clinicians
                        who prescribe drugs and the hospitals that buy and
                        administer them to the distributors and pharmacies that
                        stock and deliver them. With so many customer types,
                        it's paramount that drug companies learn as much as
                        possible about each one so that they can identify the
                        areas and people with the most profitable issues to
                        address. They can do this through a variety of research
                        measures. They can use established secondary research
                        literature or conduct primary research (such as
                        ethnographic studies and satisfaction surveys with
                        physicians, hospitals and/or patients) themselves.{" "}
                      </p>
                      <p>
                        {" "}
                        <strong>Assess the Market</strong>{" "}
                      </p>
                      <p>
                        {" "}
                        Picking the right environment (both physically and
                        generally) in which to market a drug is critical.
                        Disease patterns, political turmoil, economic stability,
                        as well as market size/segmentation and operating
                        conditions, are just a few of the key factors affecting
                        a company's overall success with a product.
                        Pharmaceutical market research can highlight any
                        obstacles or opportunities that might disturb or
                        encourage favourable outcomes.{" "}
                      </p>
                      <p>
                        {" "}
                        <strong>Provide a Competitive Analysis</strong>{" "}
                      </p>
                      <p>
                        {" "}
                        Emerging new products to the market are only one type of
                        threat. Generic products can also severely impact a
                        company's bottom line. Thus, it's imperative that
                        pharmaceutical companies keep on top of the products
                        that their competitors are developing, as well as
                        improving. They can utilize SWOT analyses and sales
                        force assessments to diagnose and treat deficiencies.
                        Focus groups and interviews can be used to expose
                        untapped technological innovations and other resources.
                        Competitive analysis can save companies from reinventing
                        the wheel. They can observe and use others' successes
                        for their own benefit instead.
                      </p>
                      <p>
                        {" "}
                        <strong>
                          Address Compliance and Other Regulatory Concerns
                        </strong>{" "}
                      </p>
                      <p>
                        {" "}
                        The pharmaceutical industry is strictly regulated. It
                        must deal with patient and data privacy issues and
                        comply with FDA and patent regulations. Pharmaceutical
                        market research can help companies monitor developing
                        patent laws and any changes to FDA legislation and
                        gather consumer feedback so that they can protect or
                        modify their practices as needed.{" "}
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="curriculum">
                    <div className="course-desc">
                      <div className="curriculum-content">
                        <h5>Modules</h5>

                        <ul>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Introduction to Regulatory Affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Why need to regulate
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Pharmaceutical drug regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Regulatory affairs profession
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Challenge to regulatory affairs profession
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Product Life Cycle - Regulatory Affairs Perspective
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            The various roles within regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Reporting of regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Organizational structure of regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Regulatory strategy
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Major regulatory authority of different countries
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Exception from the regulatory affairs agencies
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Importance of regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Responsibilities of regulatory affairs agencies
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Recent advancements in Drug Regulatory Affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="reviews">
                    <div className="course-desc">
                      <div className="review-content">
                        <p>Error: Query lp/v1/lazy-load/course-review</p>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Marcket_Reasearch;
