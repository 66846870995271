import React, { useEffect, useState } from "react";

import { MetaTags } from "react-meta-tags";
import BreadCrumb from "../Breadcrumb/BreadCrumb";

// MUI Component
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import Custom from "../assets/slider-images/custom.png";

import Layout from "../Layout/Layout";
import HomePageSlider from "../Slider/HomePageSlider";
import {
  Flag,
  Setting,
  Circle,
  Monitor,
} from "../assets/SvgIcons/HomeSVGIcons";
import { Link } from "react-router-dom";
import Profile_Photo from "../assets/team-photo/rohan-pawar-rap-analytical-home.png";
import Rap_Analytical from "../assets/team-photo/alpha-foundation-course-at-rap-analytical.png";
import Rap_Analytical_Institute from "../assets/team-photo/alpha-foundation-course-rap-analytical-institute.png";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { Avatar } from "@mui/material";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

import Logo1 from "../assets/homepage-logo/logo (1).png";
import Logo2 from "../assets/homepage-logo/logo (2).png";
import Logo3 from "../assets/homepage-logo/logo (3).png";
import Logo4 from "../assets/homepage-logo/logo (4).png";
import Logo5 from "../assets/homepage-logo/logo (5).png";
import Logo6 from "../assets/homepage-logo/logo (6).png";
import Logo7 from "../assets/homepage-logo/logo (7).png";
import Logo8 from "../assets/homepage-logo/logo (8).png";
import Logo9 from "../assets/homepage-logo/logo (9).png";
import Logo10 from "../assets/homepage-logo/logo (10).png";
import Logo11 from "../assets/homepage-logo/logo (11).png";
import Logo12 from "../assets/homepage-logo/logo (12).png";
import Logo13 from "../assets/homepage-logo/logo (13).png";
import Logo14 from "../assets/homepage-logo/logo (14).png";
import Logo15 from "../assets/homepage-logo/logo (15).png";
import Logo16 from "../assets/homepage-logo/logo (16).png";
import Logo17 from "../assets/homepage-logo/logo (17).png";
import Logo18 from "../assets/homepage-logo/logo (18).png";
import HomeMiniSlider from "../Slider/HomeMiniSlider";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

const logoSliderData = [
  Logo1,
  Logo2,
  Logo3,
  Logo4,
  Logo5,
  Logo6,
  Logo7,
  Logo8,
  Logo9,
  Logo10,
  Logo11,
  Logo12,
  Logo13,
  Logo14,
  Logo15,
  Logo16,
  Logo17,
  Logo18,
];

function Home() {
  const settings = {
    loop: true,
    autoplay: {
      delay: 1000,
      // disableOnInteraction: false,
    },
    grabCursor: true,
  };

  const [yearCount, setYearCount] = useState(0);
  const [studentCount, setStudentCount] = useState(0);
  const [sessionCount, setSessionCount] = useState(0);
  const [placementCount, setPlacementCount] = useState(0);
  const endValue = 3092;

  useEffect(() => {
    const interval = setInterval(() => {
      if (studentCount < 3092) {
        setStudentCount(studentCount + 4);
      }

      if (yearCount < 2012) {
        setYearCount(yearCount + 4);
      }

      if (sessionCount < 1926) {
        setSessionCount(sessionCount + 6);
      }

      if (placementCount < 90) {
        setPlacementCount(placementCount + 3);
      }
    }, 1);

    return () => {
      clearInterval(interval);
    };
  }, [studentCount, 3092]);

  return (
    <>
      <MetaTags>
        <title>RAP Analytical - Taking your Skills</title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/event"}>
        RAP Analytical - Taking your Skills
      </BreadcrumbsItem>
      <Layout>
        <HomePageSlider />

        <section className="section-2">
          <div className="text-content">
            <h5 className="text-center">Developing Skillsets since a Decade</h5>
            <h2 className="text-center">
              Welcome to <mark>RAP</mark> Analytical Research and Training
              Center
            </h2>
          </div>

          <div className="cards-group">
            <div className="single-card">
              <div className="single-card-icon">{Flag}</div>
              <div className="single-card-desc">
                <h3>Technical Mentoring</h3>
                <p>
                  'RAP' technical training for industries like Pharmaceutical,
                  Chemicals, Bio-Organics, Petroleum, and more.
                </p>
              </div>
            </div>

            <div className="single-card">
              <div className="single-card-icon-circle">{Setting}</div>
              <div className="single-card-desc">
                <h3>Skill Development</h3>
                <p>
                  We focus on developing skills for career. We encourage
                  potential in our students through skill mapping.
                </p>
              </div>
            </div>

            <div className="single-card">
              <div className="single-card-icon-circle">{Circle}</div>
              <div className="single-card-desc">
                <h3>Ask for Guidance</h3>
                <p>
                  Make a reservation with our expert consulting and guidance
                  team to learn what's best for your dream career.
                </p>
              </div>
            </div>

            <div className="single-card">
              <div className="single-card-icon">{Monitor}</div>
              <div className="single-card-desc">
                <h3>Remote Learning</h3>
                <p>
                  Learn from anywhere in the world on desktop, tablet or mobile
                  phone with an Internet connection.
                </p>
              </div>
            </div>
          </div>

          <div className="redirection">
            <Link to="/about/rap_analytical">Learn More About Us</Link>
          </div>
        </section>

        <section className="section-3">
          <div className="container">
            <div className="profile-card">
              <div className="profile-photo">
                <img src={Profile_Photo} alt="" />
                <h2>Mr. Rohan Pawar</h2>
                <p>
                  MS in Pharmaceutical Analysis <br />
                  (Sheffield Hallam University, England)
                </p>
              </div>
              <div className="user-desc-content">
                <div className="user-desc">
                  <h2>
                    From our <mark>Director's Desk</mark>!
                  </h2>
                  <p>
                    RAP Analytical sees 'YOU' as that asset which would play a
                    worthwhile role for that esteemed organisation. We will be
                    always in state to make you skilful and potential, to get
                    you a chance to enter in such industries, where you could
                    show your skill to grab a reputed or best deserving
                    position.
                  </p>
                </div>

                <h2>
                  <mark>
                    {" "}
                    <b> 10+</b>
                  </mark>
                  years
                </h2>

                <h3>
                  In the field of analytical research, testing, training and
                  education
                </h3>

                <Link to="/about/director_message">Read Full Message </Link>
              </div>
            </div>
          </div>
        </section>

        <div
          className="container-fluid p-0"
          style={{
            backgroundColor: "#f5f1ed",
          }}>
          <section className="section-4">
            <div className="text-wrapper">
              <h5>
                MAXIMIZE YOUR <strong>POTENTIALS</strong> AND
                <strong>OPPORTUNITIES</strong>
              </h5>
              <h2>
                Learn the secrets of <mark>Technical Career Success</mark>,
                <br />
                and place the right <mark>key</mark> in your
                <mark>career keyhole</mark>!
              </h2>
            </div>

            <div className="container slider-record-content-wrapper">
              <div className="record-content">
                <div className="single-record">
                  <h2>{yearCount}</h2>
                  <h6>Established Year</h6>
                </div>

                <div className="single-record">
                  {/* <h2>3,092+</h2> */}
                  <h2>{studentCount}+</h2>
                  <h6>Students Enrolled</h6>
                </div>

                <div className="single-record">
                  <h2>{sessionCount}+</h2>
                  <h6>Finished sessions</h6>
                </div>

                <div className="single-record">
                  <h2>{placementCount}%+</h2>
                  <h6>Placement Success</h6>
                </div>
              </div>

              {/* <div className="slider-content"> */}
              <HomeMiniSlider />
              {/* </div>   */}
            </div>

            <h5 className="text-center mt-5">
              Still Not Satisfied?
              <Link to="/testimonial" className="link">
                {" "}
                View All Testimonials
              </Link>
            </h5>
          </section>
          {/* <div className="curved-image"> */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 100 100"
            preserveAspectRatio="none"
            // height="100"
            style={{
              width: "100%",
              display: "block",
              position: "relative",
              left: "50%",
              transform: "translateX(-50%)",
              overflow: "hidden",
              height: "70px",
              backgroundColor: "#f5f1ed",
            }}>
            <path
              className="elementor-shape-fill"
              style={{ fill: "#fff" }}
              d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"></path>
          </svg>
          {/* </div> */}
        </div>

        <div className="container-fluid">
          <section className="section-5">
            <div className="container d-flex flex-column justify-content-center align-items-center">
              <div className="text-content text-center">
                <h5>Learn at your Pace</h5>
                <h1>Current Signature Courses</h1>
              </div>

              <div className="our-team-content">
                <div className="profile-card">
                  <div className="profile-img">
                    <Link to="/courses/foundation/alpha-foundation">
                      <img src={Rap_Analytical} alt=""></img>
                    </Link>
                    <div className="price">
                      <strong>₹12,500.00</strong>
                    </div>
                  </div>
                  <div className="profile-content">
                    <div className="avatar">
                      <Avatar src={AccountCircleIcon} /> <p>Team RAP</p>
                    </div>
                    <h3>Alpha Foundation Course</h3>
                    <h6>
                      A short term course is designed in such a way that the
                      student should get the practical experience …
                    </h6>

                    <div className="record-content">
                      <div className="single-record">
                        <InsertDriveFileIcon sx={{ fontSize: "24px" }} />
                        <p> 13 Lessons</p>
                      </div>
                      <div className="single-record">
                        <AccountCircleIcon sx={{ fontSize: "24px" }} />
                        <p> 86,000 Students </p>
                      </div>
                      <div className="single-record">
                        <WatchLaterIcon sx={{ fontSize: "24px" }} />
                        <p> 30 days </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="profile-card">
                  <div className="profile-img">
                    <img src={Rap_Analytical_Institute} alt=""></img>
                    <div className="price">
                      <strong>₹10,000.00</strong>
                    </div>
                  </div>
                  <div className="profile-content">
                    <div className="avatar">
                      <Avatar src={AccountCircleIcon} /> <p>Team RAP</p>
                    </div>
                    <h3>Beta Foundation Course</h3>
                    <h6>
                      A short term course is designed in such a way that the
                      student should get the practical experience …
                    </h6>

                    <div className="record-content">
                      <div className="single-record">
                        <InsertDriveFileIcon sx={{ fontSize: "24px" }} />
                        <p> 13 Lessons</p>
                      </div>
                      <div className="single-record">
                        <AccountCircleIcon sx={{ fontSize: "24px" }} />
                        <p> 31,000 Students </p>
                      </div>
                      <div className="single-record">
                        <WatchLaterIcon sx={{ fontSize: "24px" }} />
                        <p> 22 days </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bottom-content">
                <h4>
                  Get the most dedicated learning experience through our
                  courses. Also, Earn a certification for your effort and
                  passion. <br />
                  And Finally, <Link to="/demo"> Get placed right </Link>
                </h4>
              </div>

              <div className="slider">
                <div className="slide-track">
                  {logoSliderData.map((val, key) => {
                    return (
                      <div key={key} className="slide">
                        <img src={val} alt="" width="200" />
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </section>
        </div>
      </Layout>
    </>
  );
}

export default Home;
