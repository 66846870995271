import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { MetaTags } from "react-meta-tags";
import { Link } from "react-router-dom";

import BreadCrumb from "../../Breadcrumb/BreadCrumb";
import Course_Img1 from "../../assets/courses/all-courses/alpha-foundation-course-at-rap-analytical.png";
import Course_Img2 from "../../assets/courses/all-courses/alpha-foundation-course-rap-analytical-institute.png";
import Course_Img3 from "../../assets/courses/all-courses/market-research-at-rap-analytical.png";
import Course_Img4 from "../../assets/courses/all-courses/medical-coding-course-rap-analytical.png";
import Course_Img5 from "../../assets/courses/all-courses/pharmacovigilance-course-rap-analytical.png";
import Course_Img6 from "../../assets/courses/all-courses/regulatory-affairs-rap-analytical.png";

import Layout from "../../Layout/Layout";

const courseData = [
  {
    image: Course_Img1,
    price: "12,500.00",
    name: "Alpha Foundation Course",
    path: "/courses/foundation/alpha-foundation",
  },
  {
    image: Course_Img2,
    price: "10,000.00",
    name: "Beta Foundation Course",
    path: "/courses/foundation/alpha-foundation",
  },
];

function Foundation() {
  return (
    <>
      <MetaTags>
        <title>Foundation | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/event"}>Foundation</BreadcrumbsItem>

      <Layout>
        <BreadCrumb name="Category Foundation Courses" />
        <div className="container">
          <div className="all-courses-content">
            {courseData.map((val, key) => {
              return (
                <div className="single-course-details" key={key}>
                  <Link to={val.path}>
                    <div className="course-img">
                      <img src={val.image} alt="" />
                    </div>
                  </Link>
                  <div className="course-info">
                    <div className="course-price">₹{val.price}</div>
                    <div className="course-title">
                      {" "}
                      <strong>
                        <Link to={val.path}> {val.name} </Link>
                      </strong>
                      <h4>
                        <strong>
                          <Link to={"/foundation-course-registration"}>
                            Buy Now{" "}
                          </Link>
                        </strong>
                      </h4>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Foundation;
