import React, { useState } from "react";
import { MetaTags } from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Formik } from "formik";
import * as Yup from "yup";

import { Link, useLocation } from "react-router-dom";

// MUI Component
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";

// Custom Component
import Layout from "../Layout/Layout";
import BreadCrumb from "../Breadcrumb/BreadCrumb";
import QR from "../assets/payment/QR.jpeg";
import { DialogActions, DialogContent, DialogContentText } from "@mui/material";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";

// Captcha Site Key 6LcDArMlAAAAAGdUkaOSAMk78uOJMZqTNMf32LBo
// Captcha Secret Key 6LcDArMlAAAAALAN_z0s2WhuNOmYfNvTOk2EuSZf

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("*Required"),
  birthDate: Yup.date().required("*Required"),
  mobile: Yup.string()
    .min(10, "*Required")
    .max(10, "*Required")
    .matches(/^[0-9]+$/, "Phone Number Required")
    .required("*Required"),
  parentsMobile: Yup.string()
    .min(10, "*Required")
    .max(10, "*Required")
    .matches(/^[0-9]+$/, "Phone Number Required")
    .required("*Required"),
  email: Yup.string().email().required("*Required"),
  address: Yup.string().required("*Required"),
  // subject: Yup.string().required("*Required"),
  college: Yup.string().required("*Required"),
  startinDate: Yup.date().required("*Required"),
  experience: Yup.string().required("*Required"),
});

function CourseRegistration() {
  const location = useLocation();
  const { data } = location.state;

  const [open, setOpen] = useState(false);
  const [amtType, setAmtType] = useState("paid in one stroke");

  const [userinfo, setUserInfo] = useState({
    languages: [],
    response: [],
  });

  const handleCheck = (e) => {
    // Destructuring
    const { value, checked } = e.target;
    const { languages } = userinfo;

    // console.log(userinfo.response);

    // Case 1 : The user checks the box
    if (checked) {
      setUserInfo({
        languages: [...languages, value],
        response: [...languages, value],
      });
    }

    // Case 2  : The user unchecks the box
    else {
      setUserInfo({
        languages: languages.filter((e) => e !== value),
        response: languages.filter((e) => e !== value),
      });
    }
  };

  const handleAmtType = (e) => {
    setAmtType(e.target.value);
  };

  const submitHandler = async (values) => {
    const userData = `cr_name=${values.name}
      &cr_dob=${values.birthDate}
      &cr_mobile=${values.mobile}
      &cr_parent_mobile=${values.parentsMobile}
      &cr_email=${values.email}
      &cr_address=${values.address} 
      &cr_education_details=${userinfo.response}
      &cr_subject=${data.title}
      &cr_college=${values.college}
      &cr_starting_date=${values.startinDate}
      &cr_prof_exp=${values.experience}
      &cr_course_fee=${data.fee}
      &cr_pay_slot=${amtType}
      &cr_paid_fees=${
        amtType === "paid in one stroke" ? data.fee : data.paid_fee
      }`;

    await axios
      .post(
        `https://rapanalytical.com/webservice/user_registration.php?`,
        userData
      )
      .then((res) => {
        console.log(res);
        setOpen(true);
      })
      .catch((err) => {
        alert(err);
      });
  };

  return (
    <>
      <MetaTags>
        <title>Foundation Course Registration | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/event"}>
        Foundation Course Registration
      </BreadcrumbsItem>
      <Layout>
        <BreadCrumb name="Foundation Course Registration Form" />

        <div className="container-fluid">
          <div className="container">
            <div className="form-wrapper">
              <h3>
                <strong>Foundation Course Registration Form</strong>
              </h3>
              <Formik
                initialValues={{
                  name: "",
                  birthDate: "",
                  mobile: "",
                  parentsMobile: "",
                  email: "",
                  address: "",
                  college: "",
                  startinDate: "",
                  experience: "",
                }}
                validationSchema={SignupSchema}
                onSubmit={(values) => {
                  submitHandler(values);
                  // console.log(values);
                }}>
                {(props) => {
                  const {
                    values,
                    touched,
                    errors,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  } = props;
                  return (
                    <form onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-lg-6 col-sm-6 col-xl-6 single-input">
                          <label htmlFor="name">Name</label>
                          <input
                            type="text"
                            name="name"
                            value={values.name}
                            className={`${
                              errors.name && touched.name
                                ? " border-danger"
                                : ""
                            }
                         `}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.name && touched.name && (
                            <div className="error-message">{errors.name}</div>
                          )}
                        </div>
                        <div className="col-lg-6 col-sm-6 col-xl-6 single-input">
                          <label htmlFor="birthdate">Birth Date</label>
                          <input
                            type="date"
                            name="birthDate"
                            value={values.birthDate}
                            className={`${
                              errors.birthDate && touched.birthDate
                                ? " border-danger"
                                : ""
                            }
                          `}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.birthDate && touched.birthDate && (
                            <div className="error-message">
                              {errors.birthDate}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-sm-6 col-xl-6 single-input">
                          <label htmlFor="mobile">Mobile No.</label>
                          <input
                            type="text"
                            name="mobile"
                            value={values.mobile}
                            classmobile={`${
                              errors.mobile && touched.mobile
                                ? " border-danger"
                                : ""
                            }
                          `}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.mobile && touched.mobile && (
                            <div className="error-message">{errors.mobile}</div>
                          )}
                        </div>
                        <div className="col-lg-6 col-sm-6 col-xl-6 single-input">
                          <label htmlFor="parentsmobile">Parents Mobile</label>
                          <input
                            type="text"
                            name="parentsMobile"
                            value={values.parentsMobile}
                            className={`${
                              errors.parentsMobile && touched.parentsMobile
                                ? " border-danger"
                                : ""
                            }
                          `}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.parentsMobile && touched.parentsMobile && (
                            <div className="error-message">
                              {errors.parentsMobile}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12 ">
                          <label htmlFor="email">Email</label>
                          <input
                            type="text"
                            name="email"
                            value={values.email}
                            className={`${
                              errors.email && touched.email
                                ? " border-danger"
                                : ""
                            }
                          `}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.email && touched.email && (
                            <div className="error-message">{errors.email}</div>
                          )}
                        </div>

                        <div className="col-12">
                          <label htmlFor="address">Address</label>
                          <textarea
                            name="address"
                            id=""
                            rows="10"
                            value={values.address}
                            className={`${
                              errors.address && touched.address
                                ? " border-danger"
                                : ""
                            }
                         `}
                            onChange={handleChange}
                            onBlur={handleBlur}></textarea>
                          {errors.address && touched.address && (
                            <div className="error-message">
                              {errors.address}
                            </div>
                          )}
                        </div>

                        {/* ----------------------------------- */}
                        <div className="col-12 px-5">
                          <label htmlFor="qualification ">
                            Education Qualification
                          </label>

                          <div className="single-input-checkbox">
                            <input
                              type="checkbox"
                              name="education"
                              value="B. Pharm"
                              onChange={handleCheck}
                            />
                            <label htmlFor="qualification">B. Pharm</label>
                          </div>
                          <div className="single-input-checkbox">
                            <input
                              type="checkbox"
                              name="education"
                              value="M. Pharm"
                              onChange={handleCheck}
                            />

                            <label htmlFor="qualification">M. Pharm</label>
                          </div>
                          <div className="single-input-checkbox">
                            <input
                              type="checkbox"
                              name="education"
                              value="B. Sc"
                              onChange={handleCheck}
                            />

                            <label htmlFor="qualification">B. Sc</label>
                          </div>
                          <div className="single-input-checkbox">
                            <input
                              type="checkbox"
                              name="education"
                              value="M.sc"
                              onChange={handleCheck}
                            />

                            <label htmlFor="qualification">M.sc</label>
                          </div>
                          <div className="single-input-checkbox">
                            <input
                              type="checkbox"
                              name="education"
                              value="Other"
                              onChange={handleCheck}
                            />

                            <label htmlFor="qualification">Other</label>
                          </div>

                          <div
                            id="education-div"
                            className="error-message"></div>
                        </div>
                      </div>
                      {/* ------------------------- */}

                      <div className="row">
                        <div className="col-lg-6 col-sm-6 col-xl-6 single-input">
                          <label htmlFor="subject">Subject</label>
                          <input
                            type="text"
                            name="subject"
                            value={data.title}
                            disabled
                          />
                          {errors.subject && touched.subject && (
                            <div className="error-message">
                              {errors.subject}
                            </div>
                          )}
                        </div>

                        <div className="col-lg-6 col-sm-6 col-xl-6 single-input">
                          <label htmlFor="college">College</label>
                          <input
                            type="text"
                            name="college"
                            value={values.college}
                            className={`${
                              errors.college && touched.college
                                ? " border-danger"
                                : ""
                            }
                          `}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.college && touched.college && (
                            <div className="error-message">
                              {errors.college}
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-lg-6 col-sm-6 col-xl-6 single-input">
                          <label htmlFor="startingdate">Starting Date</label>
                          <input
                            type="date"
                            name="startinDate"
                            value={values.startinDate}
                            className={`${
                              errors.startinDate && touched.startinDate
                                ? " border-danger"
                                : ""
                            }
                          `}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.startinDate && touched.startinDate && (
                            <div className="error-message">
                              {errors.startinDate}
                            </div>
                          )}
                        </div>
                        <div className="col-lg-6 col-sm-6 col-xl-6 single-input">
                          <label htmlFor="experience">
                            Professional Experiense
                          </label>
                          <input
                            type="text"
                            name="experience"
                            value={values.experience}
                            className={`${
                              errors.experience && touched.experience
                                ? " border-danger"
                                : ""
                            }
                          `}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                          {errors.experience && touched.experience && (
                            <div className="error-message">
                              {errors.experience}
                            </div>
                          )}
                        </div>

                        {data.sub_fee && (
                          <div className="col-12 single-input">
                            <label htmlFor="subject">Course Fee :</label>
                            <div className="d-flex">
                              <input
                                type="radio"
                                name="couse_fee"
                                value="paid in one stroke"
                                style={{ margin: "0px -16px 0px 18px" }}
                                defaultChecked
                                onChange={handleAmtType}
                              />
                              <label htmlFor="one stroke">
                                paid in one stroke {` (${data.fee})`}
                              </label>

                              <input
                                type="radio"
                                name="couse_fee"
                                value="If paid in two slabs"
                                style={{ margin: "0px -16px 0px 18px" }}
                                onChange={handleAmtType}
                              />
                              <label htmlFor="two slabs">
                                If paid in two slabs
                                {`(${data.sub_fee})`}
                              </label>
                            </div>
                            <div
                              id="fee-status"
                              className="error-message"></div>
                            <input
                              type="text"
                              name="ammount"
                              value={
                                amtType === "paid in one stroke"
                                  ? data.fee
                                  : data.paid_fee
                              }
                              disabled
                            />
                          </div>
                        )}
                      </div>

                      <div className="row mt-2">
                        <ReCAPTCHA sitekey="6LcDArMlAAAAAGdUkaOSAMk78uOJMZqTNMf32LBo" />
                      </div>

                      <div style={{ textAlign: "center" }}>
                        <button>Proceed to Payment</button>
                      </div>
                    </form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>

        <Dialog onClose={() => setOpen(false)} open={open}>
          <DialogContent sx={{ textAlign: "center" }}>
            <img className="text-center" src={QR} alt="" width="200px" />
            <DialogContentText>
              <h6 className="mt-4 mb-3">
                You have to scan it and send the screenshot to 7666406674
              </h6>
              <p>
                <strong>Course Name : </strong> {data.title}
              </p>
              <p>
                <strong> Course Fee : </strong>
                {amtType === "paid in one stroke" ? data.fee : data.paid_fee}
              </p>
            </DialogContentText>
            <DialogActions>
              <Link
                to="/demo"
                style={{
                  textDecoration: "none",
                  color: "black",
                  background: "#c21f2d",
                  padding: "7px 10px",
                }}
                onClick={() => setOpen(false)}>
                {" "}
                Done{" "}
              </Link>
            </DialogActions>
          </DialogContent>
        </Dialog>
      </Layout>
      ;
    </>
  );
}

export default CourseRegistration;
