import React from "react";
import { MetaTags } from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { Link } from "react-router-dom";

import Layout from "../../Layout/Layout";
import BreadCrumb from "../../Breadcrumb/BreadCrumb";
import Event_Img from "../../assets/events/workshops-research-methodology.png";
import CheckIcon from "@mui/icons-material/Check";

function SingleEventInfo() {
  return (
    <>
      <MetaTags>
        <title>Online Workshop on Research Methodology | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/event"}>
        Online Workshop on Research Methodology{" "}
      </BreadcrumbsItem>

      <Layout>
        <BreadCrumb name="Online Workshop on Research Methodology" />

        <div className="container">
          <div className="singl-event-info-wrapper">
            <h1>
              <strong> Online Workshop on Research Methodology </strong>
            </h1>

            <h6>
              July 30, 2022 @ 10:30 am
              <mark>₹399</mark>
            </h6>

            <div className="event-img">
              <img src={Event_Img} alt="" />
            </div>

            <h4>
              Eligibility :{" "}
              <mark>
                For M.Pharm / M.Sc / Ph.D Canditates from all Branches
              </mark>
            </h4>

            <div className="event-ticket-details-wrapper">
              <div>
                <h4>Workshop Coverage:</h4>
                <ul>
                  <li>
                    <CheckIcon sx={{ fontSize: "17px" }} /> Literature Survey
                  </li>
                  <li>
                    <CheckIcon sx={{ fontSize: "17px" }} /> Drugs / API
                    Selection
                  </li>
                  <li>
                    <CheckIcon sx={{ fontSize: "17px" }} /> Research Hypothesis
                  </li>
                  <li>
                    <CheckIcon sx={{ fontSize: "17px" }} /> Research Work
                  </li>
                  <li>
                    <CheckIcon sx={{ fontSize: "17px" }} /> Thesis Writeup
                  </li>
                  <li>
                    <CheckIcon sx={{ fontSize: "17px" }} /> Referencing
                  </li>
                  <li>
                    <CheckIcon sx={{ fontSize: "17px" }} /> Appendix
                  </li>
                </ul>

                <div className="event-ticket">
                  <h4>Tickets</h4>

                  <p>Ticket are no longer available</p>
                </div>
              </div>

              <div className="event-details">
                <h5>
                  <strong>Details</strong>
                </h5>

                <ul>
                  <li>
                    <span>
                      <strong>Organizer</strong>
                    </span>
                    <span className="right">
                      <Link to="/rap-analytical-pvt-ltd">
                        RAP Analytical Pvt. Ltd.
                      </Link>
                    </span>
                  </li>

                  <li>
                    <span>
                      <strong>Date</strong>
                    </span>
                    <span className="right"> Jully 30 2022</span>
                  </li>

                  <li>
                    <span>
                      <strong>Phone</strong>
                    </span>
                    <span className="right">+91 7666 406 674</span>
                  </li>

                  <li>
                    <span>
                      <strong>Time</strong>
                    </span>
                    <span className="right"> 10:30am - 1:30pm IST</span>
                  </li>

                  <li>
                    <span>
                      <strong>Email</strong>
                    </span>
                    <span className="right"> info@rapanalytical.com</span>
                  </li>
                  <li>
                    <span>
                      <strong>Cost </strong>
                    </span>
                    <span className="right"> ₹399</span>
                  </li>
                </ul>

                <h5>
                  <Link to="/demo">View Organizer Website</Link>
                </h5>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default SingleEventInfo;
