import PropTypes from "prop-types";
import React, { useEffect, Suspense, lazy } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

// const Home = lazy(() => import("./Main_Pages/Home"));
import Home from "./Main_Pages/Home";
import Courses from "./Main_Pages/Courses";
import Service from "./Main_Pages/Service";
import Testimonial from "./Main_Pages/Testimonial";
import Gallary from "./Main_Pages/Gallary";
import Event from "./Main_Pages/Event";
import About from "./Main_Pages/About";
import Contact from "./Main_Pages/Contact";
import Director_Message from "./Sub_Pages/About/Director_Message";
import Rap_Analytical from "./Sub_Pages/About/Rap_Analytical";
import Foundation from "./Sub_Pages/Courses/Foundation";
import Marcket_Reasearch from "./Sub_Pages/Courses/Marcket_Reasearch";
import Medical_Coding from "./Sub_Pages/Courses/Medical_Coding";
import PharmCovigilance from "./Sub_Pages/Courses/PharmCovigilance";
import Regulatory from "./Sub_Pages/Courses/Regulatory";
import { BreadcrumbsProvider } from "react-breadcrumbs-dynamic";
import Alpha_Foundation from "./Sub_Pages/Courses/Foundation_Pages/Alpha_Foundation";
import ScrollTop from "./helper/ScrollTop";
import SingleEventInfo from "./Sub_Pages/Events/SingleEventInfo";
import AboutRAP from "./Sub_Pages/Events/AboutRAP";
import CourseRegistration from "./Main_Pages/CourseRegistration";

function App() {
  return (
    <>
      <BreadcrumbsProvider>
        <Router>
          {/* <ScrollTop> */}
          <Routes basename={"/demo"}>
            <Route path={`/demo`} element={<Home />} />

            {/* About */}
            <Route exact path={"/about"} element={<About />} />
            <Route
              exact
              path={"/about/director_message"}
              element={<Director_Message />}
            />
            <Route
              exact
              path={"/about/rap_analytical"}
              element={<Rap_Analytical />}
            />
            {/* <------     -------> */}

            {/* Courses */}
            <Route exact path={"/courses"} element={<Courses />} />
            <Route exact path="/courses/foundation" element={<Foundation />} />
            {/* Foundation Pages */}
            <Route
              exact
              path="/courses/foundation/alpha-foundation"
              element={<Alpha_Foundation />}
            />
            {/* <------     -------> */}

            <Route
              exact
              path="/courses/marcket_research"
              element={<Marcket_Reasearch />}
            />
            <Route
              exact
              path="/courses/medical_coding"
              element={<Medical_Coding />}
            />
            <Route
              exact
              path="/courses/pharmcovigilance"
              element={<PharmCovigilance />}
            />
            <Route
              exact
              path="/courses/regulatory_course"
              element={<Regulatory />}
            />
            {/* <------     -------> */}

            <Route exact path="/services" element={<Service />} />
            <Route exact path="/testimonial" element={<Testimonial />} />
            <Route exact path="/gallary" element={<Gallary />} />
            <Route exact path="/contact" element={<Contact />} />
            <Route exact path="/event" element={<Event />} />
            <Route
              exact
              path="/event/event-info"
              element={<SingleEventInfo />}
            />
            <Route
              exact
              path="/rap-analytical-pvt-ltd"
              element={<AboutRAP />}
            />

            <Route
              exact
              path="/foundation-course-registration"
              element={<CourseRegistration />}
            />
          </Routes>
          {/* </ScrollTop> */}
        </Router>
      </BreadcrumbsProvider>
    </>
  );
}

export default App;
