import React from "react";
import { MetaTags } from "react-meta-tags";
import BreadCrumb from "../../Breadcrumb/BreadCrumb";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

import Layout from "../../Layout/Layout";
import Profile_Img from "../../assets/about-us/rohan-pawar-rap-analytical.png";

function Director_Message() {
  return (
    <>
      <MetaTags>
        <title>Director Message | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/event"}>Director Message</BreadcrumbsItem>

      <Layout>
        <BreadCrumb name="Director Message" />
        <div
          className="container-fluid"
          style={{
            backgroundColor: "#f5f1ed",
          }}>
          <div className="container">
            <div className="director-message-wrapper">
              <div className="profile">
                <div className="profile-img">
                  <img src={Profile_Img} alt="" />
                </div>
                <div className="profile-desc">
                  <h2>Mr. Rohan Pawar</h2>
                  <h5>MS in Pharmaceutical Analysis</h5>
                  <h5>(Sheffield Hallam University, England)</h5>
                </div>
              </div>
            </div>
          </div>

          <div className="curved-image">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              // height="100"
              style={{
                width: "100%",
                display: "block",
                position: "relative",
                left: "50%",
                transform: "translateX(-50%)",
                overflow: "hidden",
                height: "70px",
                backgroundColor: "#f5f1ed",
              }}>
              <path
                class="elementor-shape-fill"
                style={{ fill: "#fff" }}
                d="M 0 0 L0 100 L100 100 L100 0 Q 50 200 0 0"></path>
            </svg>
          </div>
        </div>

        <div className="container">
          <div className="director-msg-content">
            <h1>Dear Student's Teacher's Researchers</h1>

            <p>
              We live in the era of a technocrat world. Where everyone is trying
              to give their best with adopted skills in their respective fields.
              Especially when we focus on fields like Pharma, Organics,
              Bio-technological, Petroleum etc. every day we could see the
              integrity in assets and range of the area companies wants to
              spread it worldwide. Any successful organisation will always
              consider their assets in terms of its skilled staff or employee.
            </p>

            <p>
              RAP Analytical see ‘YOU’ as that asset that would play a
              worthwhile role for that esteemed organisation. We will be always
              in state to make you skilful and potential, to get you a chance to
              enter in such industries, where you could show your skill to grab
              a reputed or best deserving position. RAP Analytical serving as a
              bridge between all educational institutes and the industries. We
              have been in a state to collaborate with many Pharma and allied
              industrial sectors as well as educational institutes.
            </p>

            <p>
              For last eight years RAP Analytical has gained the trust of
              researchers, students, teachers and their educational organisation
              for satisfying whatever their expectations were. We could assure
              you to take you all towards the miles of this successful journey.
            </p>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Director_Message;
