import React from "react";
import Layout from "../Layout/Layout";
import BreadCrumb from "../Breadcrumb/BreadCrumb";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { MetaTags } from "react-meta-tags";
// import { Carousel } from "react-bootstrap";

import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Images Import
import SingleImage from "../assets/gallary/row-1/seminar-on-ipr-01.webp";
import Slider1 from "../assets/gallary/row-1/New folder/seminar-on-ipr-02.jpg";
import Slider2 from "../assets/gallary/row-1/New folder/seminar-on-ipr-03.jpg";
import Slider3 from "../assets/gallary/row-1/New folder/seminar-on-ipr-04.jpg";
import Slider4 from "../assets/gallary/row-1/New folder/seminar-on-ipr-05.jpg";

// Slider2Img
import S2_Single from "../assets/gallary/row-2/students-saraswati-wani-college-01.avif";
import S2_S1 from "../assets/gallary/row-2/New folder/students-saraswati-wani-college-02.jpg";
import S2_S2 from "../assets/gallary/row-2/New folder/students-saraswati-wani-college-03.jpg";
import S2_S3 from "../assets/gallary/row-2/New folder/students-saraswati-wani-college-04.jpg";
import S2_S4 from "../assets/gallary/row-2/New folder/students-saraswati-wani-college-05.jpg";
import S2_S5 from "../assets/gallary/row-2/New folder/students-saraswati-wani-college-06.jpg";
import S2_S6 from "../assets/gallary/row-2/New folder/students-saraswati-wani-college-07.jpg";

// Slider3Img
import S3_Single from "../assets/gallary/row-3/met-bhujbal-training-01.avif";
import S3_S1 from "../assets/gallary/row-3/New folder/met-bhujbal-training-02.webp";
import S3_S2 from "../assets/gallary/row-3/New folder/met-bhujbal-training-03.jpg";

// Slider2Img
import S4_Single from "../assets/gallary/row-4/photos-with-students-01.avif";
import S4_S1 from "../assets/gallary/row-4/New folder/photos-with-students-01.jpg";
import S4_S2 from "../assets/gallary/row-4/New folder/photos-with-students-02.jpg";
import S4_S3 from "../assets/gallary/row-4/New folder/photos-with-students-03.jpg";
import S4_S4 from "../assets/gallary/row-4/New folder/photos-with-students-04.jpg";
import S4_S5 from "../assets/gallary/row-4/New folder/met-bhujbal-training-03.jpg";

const images = [
  { src: Slider1, alt: "Image 1" },
  { src: Slider2, alt: "Image 2" },
  { src: Slider3, alt: "Image 3" },
  { src: Slider4, alt: "Image 3" },
];

const Slider_2_Img = [
  { src: S2_S1, alt: "Image 3" },
  { src: S2_S2, alt: "Image 3" },
  { src: S2_S3, alt: "Image 3" },
  { src: S2_S4, alt: "Image 3" },
  { src: S2_S5, alt: "Image 3" },
  { src: S2_S6, alt: "Image 3" },
];

const Slider_3_Img = [
  { src: S3_S1, alt: "Image 3" },
  { src: S3_S2, alt: "Image 3" },
];

const Slider_4_Img = [
  { src: S4_S1, alt: "Image 3" },
  { src: S4_S2, alt: "Image 3" },
  { src: S4_S3, alt: "Image 3" },
  { src: S4_S4, alt: "Image 3" },
  { src: S4_S5, alt: "Image 3" },
];

function Gallary() {
  return (
    <>
      <MetaTags>
        <title>Gallery | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/gallery"}>Events</BreadcrumbsItem>

      <Layout>
        <BreadCrumb name="Gallery" />
        <div className="container">
          <div className="gallary-wrapper">
            <h5>
              From the House of <strong>RAP Analytical</strong>{" "}
            </h5>
            <h2>Center and Events Gallery</h2>

            <div className="gallary-single-row">
              <div className="single-image-wrapper">
                <h4>Workshops / Seminar</h4>
                <div className="single-image">
                  <img src={SingleImage} alt="" />
                </div>
              </div>
              <div className="slider-images-wrapper">
                <h1>Dec 2021</h1>
                <p>
                  Rohan Pawar, conducted Seminar on IPR in one day National
                  level workshop at SVKT College, Nashik on 21st December.
                  Special thank to Principal Dr. Medhane Sir, Dr. Bhoj Sir, Dr.
                  Manisha Aher Madam for invitation and coordination.
                </p>
                <div className="slider-images">
                  <Carousel
                    showThumbs={true}
                    showIndicators={false}
                    showStatus={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={3000}
                    transitionTime={500}
                    stopOnHover={false}
                    emulateTouch={false}
                    swipeable={true}
                    dynamicHeight={true}
                    centerMode={true}
                    showArrows={true}
                    // renderThumbs={renderThumbs}
                    // style={getCarouselStyle()}
                  >
                    {images.map((image, index) => (
                      <div key={index}>
                        <img src={image.src} alt={image.alt} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>

            <div className="left-gallary-single-row">
              <div className="left-single-image-wrapper">
                <h4>Workshops / Seminar</h4>
                <div className="left-single-image">
                  <img src={S2_Single} alt="" />
                </div>
              </div>
              <div className="left-slider-images-wrapper">
                <h1>Dec 2021</h1>
                <p>
                  Rohan Pawar, conducted Seminar on IPR in one day National
                  level workshop at SVKT College, Nashik on 21st December.
                  Special thank to Principal Dr. Medhane Sir, Dr. Bhoj Sir, Dr.
                  Manisha Aher Madam for invitation and coordination.
                </p>
                <div className="left-slider-images">
                  <Carousel
                    showThumbs={true}
                    showIndicators={false}
                    showStatus={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={3000}
                    transitionTime={500}
                    stopOnHover={false}
                    emulateTouch={false}
                    swipeable={true}
                    dynamicHeight={true}
                    centerMode={true}
                    showArrows={true}>
                    {Slider_2_Img.map((image, index) => (
                      <div key={index}>
                        <img src={image.src} alt={image.alt} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>

            <div className="gallary-single-row">
              <div className="single-image-wrapper">
                <h4>Lectures / Sessions</h4>
                <div className="single-image">
                  <img src={S3_Single} alt="" />
                </div>
              </div>
              <div className="slider-images-wrapper">
                <h1>Dec 2021</h1>
                <p>
                  Final year students from MET Bhujbal Knowledge City, Nashik
                  attended Industrial training session.. Thanks to all
                </p>
                <div className="slider-images">
                  <Carousel
                    showThumbs={true}
                    showIndicators={false}
                    showStatus={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={3000}
                    transitionTime={500}
                    stopOnHover={false}
                    emulateTouch={false}
                    swipeable={true}
                    dynamicHeight={true}
                    centerMode={true}
                    showArrows={true}>
                    {Slider_3_Img.map((image, index) => (
                      <div key={index}>
                        <img src={image.src} alt={image.alt} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>

            <div className="left-gallary-single-row">
              <div className="left-single-image-wrapper">
                <h4>Lectures / Sessions</h4>
                <div className="left-single-image">
                  <img src={S4_Single} alt="" />
                </div>
              </div>
              <div className="left-slider-images-wrapper">
                <h1>Sep 2021</h1>
                <p>Some random clicks with our beloved students</p>
                <div className="left-slider-images">
                  <Carousel
                    showThumbs={true}
                    showIndicators={false}
                    showStatus={false}
                    infiniteLoop={true}
                    autoPlay={true}
                    interval={3000}
                    transitionTime={500}
                    stopOnHover={false}
                    emulateTouch={false}
                    swipeable={true}
                    dynamicHeight={true}
                    centerMode={true}
                    showArrows={true}>
                    {Slider_4_Img.map((image, index) => (
                      <div key={index}>
                        <img src={image.src} alt={image.alt} />
                      </div>
                    ))}
                  </Carousel>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Gallary;
