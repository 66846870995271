import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { MetaTags } from "react-meta-tags";

// MUI Component
import CallIcon from "@mui/icons-material/Call";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import AddToHomeScreenIcon from "@mui/icons-material/AddToHomeScreen";

import Layout from "../../Layout/Layout";
import BreadCrumb from "../../Breadcrumb/BreadCrumb";

function AboutRAP() {
  return (
    <>
      <MetaTags>
        <title>Upcoming Events | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/event"}>Upcoming Events</BreadcrumbsItem>

      <Layout>
        <BreadCrumb name="RAP Analytical Pvt. Ltd " />

        <div className="container p-5">
          <div className="rap-content">
            <h3 className="mb-4">RAP Analytical Pvt. Ltd. </h3>
            <div className="contact">
              <CallIcon sx={{ fontSize: "19px" }} />
              <span>+91 7666 406 674</span>
            </div>
            <div className="contact">
              <MailOutlineIcon sx={{ fontSize: "19px" }} />
              <span>info@rapanalytical.com</span>
            </div>
            <div className="contact">
              <AddToHomeScreenIcon sx={{ fontSize: "19px" }} />{" "}
              <span>https://rapanalytical.com/</span>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AboutRAP;
