import React from "react";
import { MetaTags } from "react-meta-tags";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

// MUI Component
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import LockIcon from "@mui/icons-material/Lock";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import AddCardIcon from "@mui/icons-material/AddCard";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import ShareIcon from "@mui/icons-material/Share";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";

import Layout from "../../../Layout/Layout";
import CourseImg from "../../../assets/courses/alpha-foundation/alpha-foundation-course-at-rap-analytical.png";
import Course_Lab_Img from "../../../assets/courses/alpha-foundation/rap-analytical-lab-instruments-training.png";
import Instrument1 from "../../../assets/courses/alpha-foundation/rap-analytical-foundation-courses-instruments-01.png";
import Instrument2 from "../../../assets/courses/alpha-foundation/rap-analytical-foundation-courses-instruments-02.png";
import Instrument3 from "../../../assets/courses/alpha-foundation/rap-analytical-foundation-courses-instruments-03.png";
import Instrument4 from "../../../assets/courses/alpha-foundation/rap-analytical-foundation-courses-instruments-04.png";
import Instrument5 from "../../../assets/courses/alpha-foundation/rap-analytical-foundation-courses-instruments-05.png";
import Instrument6 from "../../../assets/courses/alpha-foundation/rap-analytical-foundation-courses-instruments-06.png";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

function Alpha_Foundation() {
  return (
    <>
      <MetaTags>
        <title>Regulatory Affairs | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>

      <Layout>
        <div className="container">
          <div className="course-content-wrapper">
            <div className="course-desc-content">
              <div className="course-entry-image-wrapper">
                <div className="entry-course-info text-center">
                  <div className="course-instructor">
                    <AddCardIcon />
                    <span className="meta-label"> Price </span>
                    <span
                      className="meta-value"
                      style={{ fontSize: "22px", color: "#8cb13d" }}>
                      <CurrencyRupeeIcon />
                      12,500.00
                    </span>
                  </div>
                  <div className="course-instructor">
                    <AssignmentIcon />
                    <span className="meta-label"> Instructor </span>
                    <span className="meta-value">Team RAP</span>
                  </div>
                  <div className="course-instructor">
                    <WatchLaterIcon />
                    <span className="meta-label">Duration</span>
                    <span className="meta-value">30 days</span>
                  </div>
                  <div className="course-instructor">
                    <InsertDriveFileIcon />
                    <span className="meta-label">Lectures</span>
                    <span className="meta-value">13</span>
                  </div>
                  <div className="course-instructor">
                    <AccountCircleIcon />
                    <span className="meta-label">Enrolled</span>
                    <span className="meta-value">86000 students</span>
                  </div>
                  <div className="course-instructor">
                    <GTranslateIcon />
                    <span className="meta-label">Language</span>
                    <span className="meta-value">English</span>
                  </div>

                  <div className="icons-tooltip text-center">
                    Share this course <ShareIcon sx={{ fontSize: "16px" }} />
                    <span className="icons-tooltiptext">
                      {" "}
                      <div className="icons-group">
                        <button
                          onClick={() =>
                            window.open("https://twitter.com/RAPAnalytical")
                          }>
                          <TwitterIcon />
                        </button>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.facebook.com/RAPAnalytical"
                            )
                          }>
                          <FacebookIcon />
                        </IconButton>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.instagram.com/rapanalytical/"
                            )
                          }>
                          <InstagramIcon />
                        </IconButton>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.linkedin.com/in/rapanalytical/"
                            )
                          }>
                          <LinkedInIcon />
                        </IconButton>
                      </div>
                    </span>
                  </div>

                  <h4>
                    <strong>
                      <Link
                        to={"/foundation-course-registration"}
                        state={{
                          data: {
                            title: "Alpha Foundation Course",
                            fee: "12,500",
                          },
                        }}>
                        Buy Now{" "}
                      </Link>
                    </strong>
                  </h4>
                </div>
                <div className="course-img">
                  <img src={CourseImg} alt="" />
                </div>
              </div>

              <Tab.Container defaultActiveKey="overview">
                <Nav variant="pills" className="course-info-tab-list">
                  <Nav.Item>
                    <Nav.Link eventKey="overview">
                      <h4>OverView</h4>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="curriculum">
                      <h4>Curriculum</h4>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="insrtuctor">
                      <h4>Instructor</h4>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="reviews">
                      <h4>Reviews</h4>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="overview">
                    <div className="course-desc">
                      <h3>
                        <strong>Course Description</strong>
                      </h3>

                      <p>
                        A short term course is designed in such a way that the
                        student should get the practical experience of handling
                        all analytical systems as well as will be able to tackle
                        troubleshooting. The course also includes some
                        theoretical coaching from an industrial point of view
                        including animations, videos and pictures in such a way
                        that students will never forget the actual mechanisms
                        happening in the instruments.
                      </p>

                      <h3>
                        <strong>Features</strong>
                      </h3>

                      <ul>
                        <li>Well equipped Laboratory</li>
                        <li>
                          Projector equipped Classroom with graceful environment
                        </li>
                        <li>Hands-on practical training on each instrument</li>
                        <li>Easy solutions for troubleshooting</li>
                        <li>Guidance for analytical method development</li>
                        <li>Enhancement in practical skills</li>
                        <li>Data analysis</li>
                        <li>Recent developments in analytical methods</li>
                      </ul>

                      <div className="instument-img-wrapper">
                        <div className="instrument-single-img">
                          <img src={Instrument1} alt="" />
                          <div className="instrument-single-img-name">HPLC</div>
                        </div>

                        <div className="instrument-single-img">
                          <img src={Instrument2} alt="" />
                          <div className="instrument-single-img-name">
                            UV-VIS Spectrophotometer
                          </div>
                        </div>

                        <div className="instrument-single-img">
                          <img src={Instrument3} alt="" />
                          <div className="instrument-single-img-name">
                            {" "}
                            Gas Chromatography
                          </div>
                        </div>

                        <div className="instrument-single-img">
                          <img src={Instrument4} alt="" />
                          <div className="instrument-single-img-name">
                            Friability Apparatus
                          </div>
                        </div>

                        <div className="instrument-single-img">
                          <img src={Instrument5} alt="" />
                          <div className="instrument-single-img-name">
                            Dissolution Apparatus
                          </div>
                        </div>

                        <div className="instrument-single-img">
                          <img src={Instrument6} alt="" />
                          <div className="instrument-single-img-name">
                            Disintegration Apparatus
                          </div>
                        </div>
                      </div>

                      <h3>
                        <strong>What does it cover?</strong>
                      </h3>

                      <p>
                        The practice of modern instruments explained in
                        chromatography and its fundamentals will form your
                        groundwork. Here you will learn in this course
                      </p>

                      <ul>
                        <li>Basic principles of all instruments</li>
                        <li>
                          The easy and logical ways to adjust experimental
                          variables to get desirable results
                        </li>
                        <li>
                          The best approach to undergo for various
                          chromatographic applications as well as industrial QC
                          & R&D practices.
                        </li>
                        <li>
                          Proven techniques for systematic problem-solving and
                          instrument maintenance.
                        </li>
                        <li>
                          The most effective time saving, money-saving
                          approaches to preventing common hardware problems and
                          method failures and much more.
                        </li>
                      </ul>

                      <div className="course-img">
                        <img src={Course_Lab_Img} alt="" />
                      </div>

                      <h3>
                        <strong>What will you get from this course?</strong>
                      </h3>

                      <p>
                        You will acquire a good understanding of the
                        fundamentals of chromatography and learn simple tips and
                        guidelines to make your chromatographic work easier and
                        more efficient. The techniques you will learn from this
                        course will make you apply more ideas at work and
                        appraise the applications of these modern techniques in
                        different analytical situations and also utilize them in
                        a suitable manner.
                      </p>

                      <p>
                        The course itself is a complete cognition for your
                        professional life whether in the academic or industrial
                        field.
                      </p>

                      <p>
                        The analytical, statistical, presentation skills or if
                        it is regarding research, in all manners this course
                        will develop your capability and will give you all those
                        qualities which are necessary for a perfect student,
                        perfect analyst and a complete employee. If you have
                        never taken a formal course or if you need a refresher,
                        it’s time to learn these instruments in the right way.
                      </p>

                      <p>
                        Your decision to choose this course and your experience
                        in learning these techniques is{" "}
                        <strong>Devoted to a cause of your success.</strong>{" "}
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="curriculum">
                    <div className="course-desc">
                      <div className="curriculum-content">
                        <h5>Modules</h5>
                        <p>
                          {" "}
                          <i>
                            HPLC, GC, UV-VIS, Dissolution Application,
                            Disintegration Application, Friability Application
                          </i>{" "}
                        </p>

                        <ul>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Introduction
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Chromatographic Parameters
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Principles
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Working
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Instrumentation
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Data Analysis/Interpretation
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Quantitative and Qualitative Analysis
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Method Development
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Method Validation
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Troubleshooting
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Applications
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Calculations
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Practical Training
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="insrtuctor">
                    <div className="course-desc">
                      <div className="review-content">
                        <h4>Team RAP</h4>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="reviews">
                    <div className="course-desc">
                      <div className="review-content">
                        <p>Error: Query lp/v1/lazy-load/course-review</p>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Alpha_Foundation;
