import React from "react";
import { MetaTags } from "react-meta-tags";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";

// MUI Component
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import WatchLaterIcon from "@mui/icons-material/WatchLater";

import BreadCrumb from "../Breadcrumb/BreadCrumb";
import Layout from "../Layout/Layout";

function Contact() {
  return (
    <>
      <MetaTags>
        <title>Contact | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/event"}>Contact</BreadcrumbsItem>

      <Layout>
        <BreadCrumb name="Contact" />

        <div className="container">
          <div className="contact-content-wrapper">
            <h1>For more information about our courses,</h1>
            <h1>please visit our center or you can get in touch</h1>
            <h1>with RAP Analytical via this information</h1>

            <div className="contact-info-wrapper">
              <div className="single-contact-info">
                <LocationOnIcon sx={{ fontSize: "30px", color: "#8cb13d" }} />
                <h5>Address</h5>
                <p>
                  Second Floor, Circle Plaza, Sarda Circle, Shingada Talav,
                  Renuka Nagar, Nashik, Maharashtra 422001
                </p>
              </div>

              <div className="single-contact-info">
                <PhoneEnabledIcon sx={{ fontSize: "30px", color: "#8cb13d" }} />
                <h5>Contact</h5>
                <p>
                  Hotline: <strong>+91 7666 406 674</strong>
                </p>
                <p>
                  Mobile: <strong>+91 88888 297 88</strong>
                </p>
                <p>
                  Mail: <strong>rapanalytical@gmail.com</strong>
                </p>
              </div>

              <div className="single-contact-info">
                <WatchLaterIcon sx={{ fontSize: "30px", color: "#8cb13d" }} />
                <h5>Hours of Operation</h5>
                <p>
                  Monday - Saturday:
                  <strong color="black">09:30 AM- 06:00 PM</strong>
                </p>
              </div>
            </div>

            <div className="map-content">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6067.464023151976!2d73.78363795640261!3d19.992867694410723!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bddeb0394a742eb%3A0x473fc3176ced8f7e!2sRAP%20Analytical%20Research%20and%20Training%20Center!5e0!3m2!1sen!2sin!4v1681567848050!5m2!1sen!2sin"
                width="100%"
                height="450"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"></iframe>
            </div>

            <h1 className="mt-5">
              Fill out this form for booking a consultant advising session.
            </h1>

            <div className="contact-form">
              <div className="row">
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <input type="text" name="name" placeholder="Your Name" />
                </div>
                <div className="col-lg-6 col-sm-6 col-xs-12">
                  <input
                    type="text"
                    name="phone"
                    placeholder="Your Phone Number"
                  />
                </div>

                <div className="col-12">
                  <input type="text" name="email" placeholder="Email" />
                </div>
                <div className="col-12">
                  <textarea
                    name="message"
                    id
                    rows="10"
                    placeholder="Your Message"></textarea>
                </div>
              </div>

              <button>Submit</button>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Contact;
