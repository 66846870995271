import React from "react";
import Layout from "../Layout/Layout";

function About() {
  return (
    <Layout>
      <h1>About</h1>
    </Layout>
  );
}

export default About;
