import React, { useEffect, useState } from "react";

import Slider1 from "../assets/slider-images/Slider1.png";
import Slider2 from "../assets/slider-images/Slider2.png";
import Slider3 from "../assets/slider-images/Slider3.png";
import Slider4 from "../assets/slider-images/Slider4.png";
import { Link } from "react-router-dom";

import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";

const slides = [
  {
    title: "Learn with us!",
    description:
      "Data Analysis, Method Development, HPLC, Practical Training, etc.",
    button: "See All Courses",
    image: Slider1,
    path: "courses",
  },
  {
    title: "Research & Training Center",
    description: "Talking your Skills to the Next Level with Advanced Method.",
    button: "More About RAP",
    image: Slider2,
    path: "about/rap_analytical",
  },
  {
    title: "Skillset Mystery ",
    description:
      "Only training dosen't assure employment, but expert skills does Seo Testimonials.",
    button: "See Testimonials",
    image: Slider3,
    path: "testimonial",
  },
  {
    title: "Learn with us!",
    description:
      "Get placed right instantly in various industries with our courses.",
    button: "See All Courses",
    image: Slider4,
    path: "courses",
  },
];

function HomePageSlider() {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const removeinterval = setInterval(() => {
      let img = document.querySelector(".my-img");
      img.classList.remove("img");

      let heading = document.querySelector(".slider-heading");
      heading.classList.remove("slider-heading-animate");

      let title = document.querySelector(".slider-title");
      title.classList.remove("slider-title-animate");

      let button = document.querySelector(".slider-button");
      button.classList.remove("slider-button-animate");
    }, 6000);

    const interval = setInterval(() => {
      let img = document.querySelector(".my-img");
      img.classList.add("img");

      let heading = document.querySelector(".slider-heading");
      heading.classList.add("slider-heading-animate");

      let title = document.querySelector(".slider-title");
      title.classList.add("slider-title-animate");

      let button = document.querySelector(".slider-button");
      button.classList.add("slider-button-animate");

      const newIndex =
        currentIndex === slides.length - 1 ? 0 : currentIndex + 1;
      setCurrentIndex(newIndex);
    }, 7000);

    return () => {
      clearInterval(interval);
      clearInterval(removeinterval);
    };
  }, [currentIndex]);

  const handlePrevClick = () => {
    let img = document.querySelector(".my-img");
    img.classList.remove("img");
    const newIndex = currentIndex === 0 ? slides.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
    img.classList.add("img");
  };

  const handleNextClick = () => {
    let img = document.querySelector(".my-img");
    img.classList.remove("img");
    const newIndex = currentIndex === slides.length - 1 ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
    img.classList.add("img");
  };
  return (
    <>
      <div className="home-page-slider">
        <div class="progress">
          <div class="progress-value"></div>
        </div>
        <div className="image-slider">
          <div className="image-slider-container">
            <div className="slider-image-content-wrapper">
              <div className="slider-content">
                <h4 className="slider-title">{slides[currentIndex].title}</h4>
                <h1 className="slider-heading">
                  {slides[currentIndex].description}
                </h1>

                <button className="slider-button">
                  <Link to={`/${slides[currentIndex].path}`}>
                    {slides[currentIndex].button}
                  </Link>
                </button>
              </div>
              <div className="slider-img">
                <img
                  className="my-img"
                  src={slides[currentIndex].image}
                  alt={`Image ${currentIndex + 1}`}
                />
              </div>
            </div>
          </div>
          <div className="button-container">
            <button onClick={handlePrevClick} className="prevButton">
              <KeyboardArrowLeftIcon
                sx={{ color: "white", fontSize: "25px", fontWeight: "900" }}
              />
            </button>
            <button onClick={handleNextClick} className="nextButton">
              <KeyboardArrowRightIcon
                sx={{ color: "white", fontSize: "25px", fontWeight: "900" }}
              />
            </button>
          </div>
        </div>
      </div>
    </>
  );
}

export default HomePageSlider;
