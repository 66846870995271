import React from "react";

import { MetaTags } from "react-meta-tags";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

// MUI Component
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import LockIcon from "@mui/icons-material/Lock";
import CourseImg from "../../assets/courses/pharmaccovigilance/pharmacovigilance-course-rap-analytical.png";

import ShareIcon from "@mui/icons-material/Share";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import AutoModeIcon from "@mui/icons-material/AutoMode";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";

import Layout from "../../Layout/Layout";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

function PharmCovigilance() {
  return (
    <>
      <MetaTags>
        <title>Regulatory Affairs | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>

      <Layout>
        <div className="container">
          <div className="course-content-wrapper">
            <div className="course-desc-content">
              <div className="course-entry-image-wrapper">
                <div className="entry-course-info text-center">
                  <div className="course-instructor">
                    <AssignmentIcon />
                    <span className="meta-label"> Instructor </span>
                    <span className="meta-value">Team RAP</span>
                  </div>

                  <div className="course-instructor">
                    <WatchLaterIcon />
                    <span className="meta-label">Duration</span>
                    <span className="meta-value">3 Months</span>
                  </div>

                  <div className="course-instructor">
                    <WatchLaterIcon />
                    <span className="meta-label">Weekend Batches</span>
                    <span className="meta-value">
                      All 4 Sundays + First and Last Saturday
                    </span>
                  </div>

                  <div className="course-instructor">
                    <WatchLaterIcon />
                    <span className="meta-label">Time</span>
                    <span className="meta-value">8am to 12pm</span>
                  </div>

                  <div className="course-instructor">
                    <AutoModeIcon />
                    <span className="meta-label">Mode</span>
                    <span className="meta-value">Online(Zoom Platform)</span>
                  </div>

                  <div className="course-instructor extra-text-content">
                    <WorkspacePremiumIcon />
                    <span className="meta-label">Relevant for</span>
                    <span className="meta-value extra-text">
                      : Life Science Students (B.Sc. & M. Sc.), B. Pharm, M.
                      Pharm, D. Pharm, BAMS, BHMS,{" "}
                    </span>
                  </div>

                  <div className="course-instructor">
                    <CurrencyRupeeIcon />
                    <span className="meta-label">Fees</span>
                    <span className="meta-value">
                      : Rs. 18000 (If paid in one stroke)
                    </span>
                  </div>

                  <div className="course-instructor">
                    <CurrencyRupeeIcon />
                    <span className="meta-label">
                      Rs. 20000 (If paid in two slabs)
                    </span>
                  </div>

                  <div className="course-instructor">
                    <GTranslateIcon />
                    <span className="meta-label">Language</span>
                    <span className="meta-value">English</span>
                  </div>

                  <div className="icons-tooltip text-center">
                    Share this course <ShareIcon sx={{ fontSize: "16px" }} />
                    <span className="icons-tooltiptext">
                      {" "}
                      <div className="icons-group">
                        <button
                          onClick={() =>
                            window.open("https://twitter.com/RAPAnalytical")
                          }>
                          <TwitterIcon />
                        </button>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.facebook.com/RAPAnalytical"
                            )
                          }>
                          <FacebookIcon />
                        </IconButton>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.instagram.com/rapanalytical/"
                            )
                          }>
                          <InstagramIcon />
                        </IconButton>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.linkedin.com/in/rapanalytical/"
                            )
                          }>
                          <LinkedInIcon />
                        </IconButton>
                      </div>
                    </span>
                  </div>

                  <h4>
                    <strong>
                      <Link
                        to={"/foundation-course-registration"}
                        state={{
                          data: {
                            title: "Pharmacovigilance",
                            fee: "18,000",
                            sub_fee: "20,000",
                            paid_fee: "10,000",
                          },
                        }}>
                        Buy Now{" "}
                      </Link>
                    </strong>
                  </h4>
                </div>
                <div className="course-img">
                  <img src={CourseImg} alt="" />
                </div>
              </div>

              <Tab.Container defaultActiveKey="overview">
                <Nav variant="pills" className="course-info-tab-list">
                  <Nav.Item>
                    <Nav.Link eventKey="overview">
                      <h4>OverView</h4>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="overview">
                    <div className="course-desc">
                      <p>
                        Pharmacovigilance (PV) is the science and activities
                        relating to the detection, assessment, understanding and
                        prevention of adverse effects or any other drug-related
                        problems. Pharmacovigilance involves reporting these
                        events to ensure that they are communicated to health
                        care professionals who will then inform the patients.
                        This involves monitoring patients for adverse reactions
                        when they receive treatment therapeutically or
                        prophylactically.
                      </p>

                      <h3>The main goals of PV are:</h3>
                      <ul>
                        <li>to detect new signals about a treatment;</li>
                        <li>
                          to identify any causal relationship between an adverse
                          reaction and a treatment;
                        </li>
                        <li>
                          to take appropriate action if necessary, such as
                          updating warnings on product labelling;
                        </li>
                        <li>
                          to gather information from healthcare professionals,
                          patients and consumers that can help us evaluate
                          whether treatments are working effectively when used
                          in clinical practice;
                        </li>
                      </ul>

                      <h2>
                        Pharmacovigilance involves more than just reporting
                        adverse drug reactions (ADRs). It also includes risk
                        management and coordination of a patient’s care.
                      </h2>

                      <p>
                        As a multidisciplinary field of science,
                        pharmacovigilance focuses on the overall safety of
                        healthcare products as well as the risk-benefit
                        relationship for patients and other stakeholders to
                        ensure safe and effective drugs are administered. The
                        fields of pharmacology, epidemiology, clinical
                        pharmacology, clinical research, pharmacoeconomics, and
                        regulatory affairs are all interlinked with each other
                        to ensure that there is an appropriate balance between
                        benefits and risks when treatment is used in humans.
                        Beyond reporting adverse drug reactions (ADRs), PV also
                        considers both physical and mental health needs
                        throughout a patient’s lifetime as a result of
                        monitoring possible side effects caused by a treatment.
                      </p>

                      <h3>Scope in Pharmacovigilance</h3>
                      <p>
                        A career in Pharmacovigilance (PV) demands basic
                        qualifications that include undergraduate and/or
                        graduate degrees in human medicine, veterinary medicine,
                        nursing, pharmacy, or allied health sciences. Direct
                        exposure in collecting and organizing PV data, their
                        effective processing, analyzing, and further
                        implications are essentially required for a student who
                        aspires to such a career. There are several institutions
                        across our nation where an individual can attend this
                        course having a duration of one to two years, following
                        graduation degree with a suitable degree to achieve an
                        initial orientation and exposure to some data or case
                        studies. This would help them eventually in preparing
                        for a real job opportunity.
                      </p>
                      <p>
                        Presently, top pharmaceutical companies and consulting
                        firms are the important ones that offer
                        Pharmacovigilance positions in bulk as jobs in the
                        Indian market. Students trained in Pharmacovigilance
                        would find potential job opportunities in the following
                        sectors:
                      </p>

                      <ul>
                        <li>
                          Pharmaceutical Companies, either MNCs or Indian &
                          Biotech companies
                        </li>
                        <li>Clinical Research Institutes</li>
                        <li>KPOs such as Accenture, Quintiles, etc.</li>
                        <li>Regulatory Agencies such as DCG (I) & CDSCO</li>
                        <li>
                          Pharmacovigilance units within Medical colleges &
                          Hospitals
                        </li>
                      </ul>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="curriculum">
                    <div className="course-desc">
                      <div className="curriculum-content">
                        <h5>Modules</h5>

                        <ul>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Introduction to Regulatory Affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Why need to regulate
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Pharmaceutical drug regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Regulatory affairs profession
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Challenge to regulatory affairs profession
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Product Life Cycle - Regulatory Affairs Perspective
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            The various roles within regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Reporting of regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Organizational structure of regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Regulatory strategy
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Major regulatory authority of different countries
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Exception from the regulatory affairs agencies
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Importance of regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Responsibilities of regulatory affairs agencies
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Recent advancements in Drug Regulatory Affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="reviews">
                    <div className="course-desc">
                      <div className="review-content">
                        <p>Error: Query lp/v1/lazy-load/course-review</p>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default PharmCovigilance;
