import React, { useCallback, useState } from "react";
import ImageViewer from "react-simple-image-viewer";

import Layout from "../Layout/Layout";
import rapanalytical1 from "../assets/service-photos/rapanalytical1.png";
import rapanalytical2 from "../assets/service-photos/rapanalytical2.png";
import rapanalytical3 from "../assets/service-photos/rapanalytical3.png";
import rapanalytical4 from "../assets/service-photos/rapanalytical4.png";
import rapanalytical5 from "../assets/service-photos/rapanalytical5.png";
import rapanalytical6 from "../assets/service-photos/rapanalytical6.png";
import BreadCrumb from "../Breadcrumb/BreadCrumb";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { MetaTags } from "react-meta-tags";

const images = [
  rapanalytical1,
  rapanalytical2,
  rapanalytical3,
  rapanalytical4,
  rapanalytical5,
  rapanalytical6,
];
function Service() {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);

  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  return (
    <>
      <MetaTags>
        <title>Service | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>
      <BreadcrumbsItem to={"/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={"/service"}>Service</BreadcrumbsItem>

      <Layout>
        <BreadCrumb name="Service" />
        <div className="container">
          <div className="d-flex flex-wrap justify-content-center p-5">
            {images.map((src, index) => (
              <img
                src={src}
                onClick={() => openImageViewer(index)}
                width="320"
                key={index}
                style={{ margin: "2px", cursor: "pointer" }}
                alt=""
              />
            ))}
            {isViewerOpen && (
              <ImageViewer
                src={images}
                currentIndex={currentImage}
                disableScroll={false}
                closeOnClickOutside={true}
                onClose={closeImageViewer}
              />
            )}{" "}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Service;
