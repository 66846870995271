import React from "react";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import { MetaTags } from "react-meta-tags";
import Tab from "react-bootstrap/Tab";
import Nav from "react-bootstrap/Nav";

// MUI Component
import AssignmentIcon from "@mui/icons-material/Assignment";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import LockIcon from "@mui/icons-material/Lock";

import Layout from "../../Layout/Layout";
import BreadCrumb from "../../Breadcrumb/BreadCrumb";
import CourseImg from "../../assets/courses/regulator-affairs/regulatory-affairs-rap-analytical.png";

import ShareIcon from "@mui/icons-material/Share";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import { IconButton } from "@mui/material";
import { Link } from "react-router-dom";

function Regulatory() {
  return (
    <>
      <MetaTags>
        <title>Regulatory Affairs | RAP </title>
        <meta name="RAP Analytical" content="RAP Analytical" />
      </MetaTags>

      <Layout>
        <div className="container">
          <div className="course-content-wrapper">
            <div className="course-desc-content">
              <div className="course-entry-image-wrapper">
                <div className="entry-course-info text-center">
                  <div className="course-instructor">
                    <AssignmentIcon />
                    <span className="meta-label"> Instructor </span>
                    <span className="meta-value">Team RAP</span>
                  </div>

                  <div className="course-instructor">
                    <WatchLaterIcon />
                    <span className="meta-label">Duration</span>
                    <span className="meta-value">22 days</span>
                  </div>

                  <div className="course-instructor">
                    <InsertDriveFileIcon />
                    <span className="meta-label">Lectures</span>
                    <span className="meta-value">15</span>
                  </div>

                  <div className="course-instructor">
                    <AccountCircleIcon />
                    <span className="meta-label">Enrolled</span>
                    <span className="meta-value">0 students</span>
                  </div>

                  <div className="course-instructor">
                    <GTranslateIcon />
                    <span className="meta-label">Language</span>
                    <span className="meta-value">English</span>
                  </div>

                  <div className="icons-tooltip text-center">
                    Share this course <ShareIcon sx={{ fontSize: "16px" }} />
                    <span className="icons-tooltiptext">
                      {" "}
                      <div className="icons-group">
                        <button
                          onClick={() =>
                            window.open("https://twitter.com/RAPAnalytical")
                          }>
                          <TwitterIcon />
                        </button>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.facebook.com/RAPAnalytical"
                            )
                          }>
                          <FacebookIcon />
                        </IconButton>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.instagram.com/rapanalytical/"
                            )
                          }>
                          <InstagramIcon />
                        </IconButton>

                        <IconButton
                          onClick={() =>
                            window.open(
                              "https://www.linkedin.com/in/rapanalytical/"
                            )
                          }>
                          <LinkedInIcon />
                        </IconButton>
                      </div>
                    </span>
                  </div>

                  {/* <h4>
                    <strong>
                      <Link
                        to={"/foundation-course-registration"}
                        state={{
                          data: {
                            title: "Pharmacovigilance",
                            fee: "18,000",
                            sub_fee: "20,000",
                            paid_fee: "10,000",
                          },
                        }}>
                        Buy Now{" "}
                      </Link>
                    </strong>
                  </h4> */}
                </div>
                <div className="course-img">
                  <img src={CourseImg} alt="" width="600px" />
                </div>
              </div>

              <Tab.Container defaultActiveKey="overview">
                <Nav variant="pills" className="course-info-tab-list">
                  <Nav.Item>
                    <Nav.Link eventKey="overview">
                      <h4>OverView</h4>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="curriculum">
                      <h4>Curriculum</h4>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="reviews">
                      <h4>Reviews</h4>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
                <Tab.Content>
                  <Tab.Pane eventKey="overview">
                    <div className="course-desc">
                      <h3>
                        {" "}
                        <strong> What is regulatory affairs? </strong>
                      </h3>

                      <p>
                        Regulatory affairs is a profession developed from the
                        desire of governments to protect public health by
                        controlling the safety and efficacy of products in areas
                        including pharmaceuticals, veterinary medicines, medical
                        devices, pesticides, agrochemicals, cosmetics and
                        complementary medicines, and by the companies
                        responsible for the discovery, testing, manufacture and
                        marketing of these products wanting to ensure that they
                        supply products that are safe and make a worthwhile
                        contribution to public health and welfare. A new class
                        of professionals emerged to handle these regulatory
                        matters for companies.
                      </p>

                      <h3>
                        {" "}
                        <strong>
                          {" "}
                          The role of the regulatory professional{" "}
                        </strong>
                      </h3>
                      <p>Regulatory professionals are responsible for:</p>
                      <ul>
                        <li>
                          Keeping track of the ever-changing legislation in all
                          the regions in which a company wishes to distribute
                          its products
                        </li>
                        <li>
                          Advising on legal and scientific restraints and
                          requirements
                        </li>
                        <li>
                          Collecting, collating and evaluating scientific data
                        </li>
                        <li>
                          Presenting registration documents to regulatory
                          agencies and carrying out any subsequent negotiations
                          necessary to obtain or maintain marketing
                          authorisation for the products concerned
                        </li>
                        <li>
                          Giving strategic and technical advice at the highest
                          level in their companies, making an important
                          contribution both commercially and scientifically to
                          the success of a development programme and the company
                          as a whole
                        </li>
                      </ul>

                      <p>
                        Additionally, the regulatory affairs department will
                        often take part in the development of product marketing
                        concepts and is commonly required to approve packaging
                        and advertising before it is used commercially.
                      </p>

                      <h3>
                        {" "}
                        <strong>
                          {" "}
                          Resources for starting out in regulatory affairs{" "}
                        </strong>
                      </h3>
                      <p>
                        If you are looking for your first role in regulatory
                        affairs for human or animal medicines, or for medical
                        devices, TOPRA's careers pages are an essential port of
                        call. As a leading professional body for healthcare
                        regulatory affairs, we are committed to delivering the
                        support you need throughout your career.
                      </p>

                      <h6>
                        {" "}
                        <strong>
                          Here are some useful tips for starting your regulatory
                          affairs career:
                        </strong>{" "}
                      </h6>

                      <ul>
                        <li>
                          Be prepared to take another role in the drug or device
                          development process as a stepping stone.
                        </li>
                        <li>
                          Review your CV for skills and experience that would be
                          particularly transferable (eg, good communication
                          skills, project management and/or the ability to
                          synthesise information).
                        </li>
                      </ul>

                      <p>
                        Enrol with a specialist regulatory recruitment
                        consultant who can advise you.
                      </p>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="curriculum">
                    <div className="course-desc">
                      <div className="curriculum-content">
                        <h5>Modules</h5>

                        <ul>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Introduction to Regulatory Affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Why need to regulate
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Pharmaceutical drug regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Regulatory affairs profession
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Challenge to regulatory affairs profession
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Product Life Cycle - Regulatory Affairs Perspective
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            The various roles within regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Reporting of regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Organizational structure of regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Regulatory strategy
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Major regulatory authority of different countries
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Exception from the regulatory affairs agencies
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Importance of regulatory affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li>
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Responsibilities of regulatory affairs agencies
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                          <li className="active">
                            <InsertDriveFileIcon
                              sx={{
                                fontSize: "20px",
                                padding: "0px 5px 0px 0px",
                              }}
                            />
                            Recent advancements in Drug Regulatory Affairs
                            <LockIcon
                              sx={{
                                fontSize: "17px",
                                float: "right",
                              }}
                            />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </Tab.Pane>

                  <Tab.Pane eventKey="reviews">
                    <div className="course-desc">
                      <div className="review-content">
                        <p>Error: Query lp/v1/lazy-load/course-review</p>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default Regulatory;
